import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';
import { useFetchData } from '../../useFetchData';
import { CUSTOMER_INVOICE_SETTINGS_PATH } from '../automatorApiPaths';
import CustomerInvoiceSettings from '../../../domain/automator/customerInvoiceRequests/CustomerInvoiceSettings';

export const useFetchCustomerInvoiceSettings = () => {
  const url = useAutomatorApiAccountUrl(CUSTOMER_INVOICE_SETTINGS_PATH);
  return useFetchData<CustomerInvoiceSettings>(url, [EndpointQueryKey.CUSTOMER_INVOICE_SETTINGS]);
};
