import { useTranslation } from 'react-i18next';
import { TableTabs } from '../../../../components/TableTabs';
import { tokens } from '../../../../locales/translationTokens';

interface StockTabsProps {
  currentTab: StockTab;
  setTab: (tab: StockTab) => void;
}

export enum StockTab {
  CURRENT_STOCK = 'CURRENT_STOCK',
  STOCK_INSIGHT = 'STOCK_INSIGHT',
  ORDERED_RESTOCK = 'ORDERED_RESTOCK',
  DELIVERED_RESTOCK = 'DELIVERED_RESTOCK',
  SETTINGS = 'SETTINGS',
}

const StockTabs = ({ currentTab, setTab }: StockTabsProps) => {
  const { t } = useTranslation();

  const tabOptions = [
    {
      label: t(tokens.automator.stock.tabs.current_stock),
      value: StockTab.CURRENT_STOCK,
    },
    {
      label: t(tokens.automator.stock.tabs.stock_insight),
      value: StockTab.STOCK_INSIGHT,
    },
    {
      label: t(tokens.automator.stock.tabs.ordered_restock),
      value: StockTab.ORDERED_RESTOCK,
    },
    {
      label: t(tokens.automator.stock.tabs.delivered_restock),
      value: StockTab.DELIVERED_RESTOCK,
    },
    {
      label: t(tokens.automator.stock.tabs.settings),
      value: StockTab.SETTINGS,
    },
  ];

  return (
    <TableTabs
      value={currentTab}
      onChange={(tab: StockTab) => {
        setTab(tab);
      }}
      tabOptions={tabOptions}
    />
  );
};

export default StockTabs;
