import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { PRODUCTS_PATH } from '../automatorApiPaths';
import ProductStock from '../../../domain/automator/stock/ProductStock';
import { ProductStocksQueryKey } from '../../queryKeys/ProductStocksQueryKey';

export const useFetchProductStocks = (productId: number) => {
  const url = useAutomatorApiAccountUrl(PRODUCTS_PATH + `/${productId}/stocks`);
  return useFetchData<ProductStock[]>(url, ProductStocksQueryKey(productId));
};
