import { CollapsableTableRowDivider } from '../../shared/CollapsableTableRowDivider';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import { useFetchOrders } from '../../../../api/automator/orders/useFetchOrders';
import { OrderStatus } from '../../../../domain/automator/orders/OrderStatus';
import OrdersPerDayRow from '../../orders/components/OrdersPerDayRow';
import { OrdersMode } from '../../orders/OrdersPage';

interface SalesStockAdjustmentTableRowProps {
  productId: number;
  date: string;
  quantity: number;
}

const SalesStockAdjustmentTableRow = ({
  productId,
  date,
  quantity,
}: SalesStockAdjustmentTableRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { data: orders, isLoading } = useFetchOrders(
    OrderStatus.SHIPPED,
    productId,
    undefined,
    date,
    date,
    !isExpanded
  );

  return (
    <>
      <CollapsableTableRowDivider
        date={date}
        isCollapsable={true}
        isCollapsed={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
        additionalInfo={
          <Stack
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={10}
          >
            {quantity}
          </Stack>
        }
      />

      {isExpanded && (
        <OrdersPerDayRow
          isExpanded={isExpanded}
          distributionParty={undefined}
          isLoading={isLoading}
          orders={orders?.orders}
          selectedCountries={[]}
          mode={OrdersMode.VIEW}
        />
      )}
    </>
  );
};

export default SalesStockAdjustmentTableRow;
