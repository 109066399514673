import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { TableEmpty } from '../../../../components/TableEmpty';
import { CustomerInvoiceRequestsTableRow } from './CustomerInvoiceRequestsTableRow';
import { useFetchInvoiceCustomerRequests } from '../../../../api/automator/customerInvoiceRequests/useFetchInvoiceCustomerRequests';

export const CustomerInvoiceRequestsListTable = () => {
  const { data, isLoading } = useFetchInvoiceCustomerRequests();

  const { t } = useTranslation();

  if (isLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  const customerInvoiceRequests = data!.customerInvoiceRequests;

  if (!customerInvoiceRequests || customerInvoiceRequests.length === 0) {
    return <TableEmpty message={t(tokens.common.no_items)} />;
  }

  const sortedCustomerInvoiceRequests = customerInvoiceRequests.sort((a, b) => {
    return new Date(b.requestedDateTime).getTime() - new Date(a.requestedDateTime).getTime();
  });

  return (
    <Scrollbar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t(tokens.automator.customer_invoice_requests.order)}</TableCell>
            <TableCell>{t(tokens.automator.customer_invoice_requests.placed_order_at)}</TableCell>
            <TableCell>{t(tokens.automator.customer_invoice_requests.status.status)}</TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {sortedCustomerInvoiceRequests.map((customerInvoiceRequest) => (
            <CustomerInvoiceRequestsTableRow
              key={customerInvoiceRequest.id}
              customerInvoiceRequest={customerInvoiceRequest}
            />
          ))}
        </TableBody>
      </Table>
    </Scrollbar>
  );
};
