import Stack from '@mui/material/Stack';
import SpokeIcon from '@mui/icons-material/LocalOfferOutlined';
import { convertCentsToEuros } from '../../../pd/helpers/convertCentsToEuros';

interface StockUnitPriceProps {
  amount: number | null;
}

export const StockUnitPrice = ({ amount }: StockUnitPriceProps) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <SpokeIcon />
      {amount ? '€ ' + convertCentsToEuros(amount) : ' -'}
    </Stack>
  );
};
