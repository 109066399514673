import { TextField, TextFieldProps } from '@mui/material';
import { NumericFormat } from 'react-number-format';

interface CurrencyTextFieldProps {
  value: number;
  onChange: (value: number) => void;
  textFieldProps?: TextFieldProps;
  label?: string;
}

const CustomInput = (props: TextFieldProps) => {
  return <TextField {...props} />;
};

const CurrencyTextField = ({ value, onChange, textFieldProps, label }: CurrencyTextFieldProps) => {
  return (
    <NumericFormat
      // @ts-ignore
      value={(value / 100) as number}
      onValueChange={(values) => {
        const { floatValue, value: rawValue } = values;

        // Treat ',' and ',<digit>' inputs correctly
        if (rawValue === ',' || rawValue === '') {
          onChange(0); // ',' is treated as 0
        } else if (rawValue.startsWith(',') && floatValue !== undefined) {
          onChange(Math.round(floatValue * 10)); // Handle cent values like ',1' -> 0.10
        } else if (floatValue !== undefined) {
          onChange(floatValue * 100); // Normal behavior
        }
      }}
      customInput={CustomInput}
      decimalSeparator=","
      allowLeadingZeros={false}
      decimalScale={2}
      fixedDecimalScale
      prefix="€"
      allowNegative={false}
      label={label}
      {...textFieldProps}
    />
  );
};

export default CurrencyTextField;
