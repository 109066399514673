import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { ActionIcon } from '../../orders/components/ActionIcon';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import { tokens } from '../../../../locales/translationTokens';
import { ProductStockData, ProductStockForm } from './ProductStockForm';
import { Box } from '@mui/system';
import TextField from '@mui/material/TextField';
import { SupplierDropdownSelect } from '../components/SupplierDropdownSelect';
import ActionButton from '../../../../components/ActionButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ProductStockStatus } from '../../../../domain/automator/stock/ProductStockStatus';

interface RestockFormProps {
  onSave?: (supplierId: number | null, daysUntilDelivery: number, item: ProductStockData[]) => void;
  productId?: number;
}

export const RestockForm = ({ onSave, productId }: RestockFormProps) => {
  const createNewItem = (productId?: number) => {
    return {
      product_id: productId || 0,
      quantity: 1,
      unit_price_in_cents: 0,
      unit_transport_cost_in_cents: 0,
      total_cubic_meters: 0,
      total_weight_in_kg: 0,
    };
  };

  const { t } = useTranslation();

  const [supplierId, setSupplierId] = useState<number | null>(null);
  const [daysUntilDelivery, setDaysUntilDelivery] = useState<number>(0);
  const [deliveryStatus, setDeliveryStatus] = useState<ProductStockStatus>(
    ProductStockStatus.DEPLETED
  );

  const [productStocks, setProductStocks] = useState<ProductStockData[]>([
    createNewItem(productId),
  ]);

  const onAddItem = () => {
    setProductStocks(productStocks.concat(createNewItem()));
  };

  const onUpdateItem = (index: number, item: ProductStockData) => {
    setProductStocks(productStocks.map((restockItem, i) => (i === index ? item : restockItem)));
  };

  const onRemoveItem = (index: number) => {
    setProductStocks(productStocks.filter((_, i) => i !== index));

    if (productStocks.length <= 1) {
      setProductStocks([createNewItem()]);
    }
  };

  useEffect(() => {
    if (deliveryStatus == ProductStockStatus.DELIVERED && daysUntilDelivery > 0) {
      setDaysUntilDelivery(0);
    }
  }, [deliveryStatus]);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      gap={2}
    >
      <Stack
        direction="row"
        gap={2}
      >
        <ToggleButtonGroup
          value={deliveryStatus}
          exclusive
          onChange={(_, newValue) => {
            if (newValue) setDeliveryStatus(newValue);
          }}
          aria-label="delivery status"
        >
          <ToggleButton
            value={ProductStockStatus.DELIVERED}
            aria-label="delivered"
          >
            {t(tokens.automator.stock.product_stock_status.delivered)}
          </ToggleButton>
          <ToggleButton
            value={ProductStockStatus.ORDERED}
            aria-label="ordered"
          >
            {t(tokens.automator.stock.product_stock_status.ordered)}
          </ToggleButton>
        </ToggleButtonGroup>

        {deliveryStatus == ProductStockStatus.ORDERED && (
          <Box width={300}>
            <TextField
              label={t(tokens.automator.stock.restock_dialog.days_until_delivery)}
              variant="outlined"
              value={daysUntilDelivery}
              onChange={(e) => setDaysUntilDelivery(parseInt(e.target.value))}
            />
          </Box>
        )}
      </Stack>

      <Box width={500}>
        <SupplierDropdownSelect
          onSelect={setSupplierId}
          supplierId={supplierId}
        />
      </Box>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        gap={1}
      >
        {productStocks.map((item, index) => (
          <ProductStockForm
            key={index}
            item={item}
            onUpdate={(item) => onUpdateItem(index, item)}
            onRemove={() => onRemoveItem(index)}
          />
        ))}
      </Stack>

      <ActionIcon
        icon={<PlusIcon />}
        tooltip={tokens.automator.stock.restock_dialog.add_item}
        onClick={() => onAddItem()}
      />

      <ActionButton
        isDisabled={productStocks.some((item) => item.product_id == 0 || item.product_id == null)}
        color="primary"
        label={t(tokens.automator.stock.restock_dialog.save)}
        onClick={() => onSave && onSave(supplierId, daysUntilDelivery, productStocks)}
      />
    </Stack>
  );
};
