import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { PRODUCTS_PATH } from '../automatorApiPaths';
import { StockAdjustmentType } from '../../../domain/automator/stock/StockAdjustmentType';
import StockAdjustment from '../../../domain/automator/stock/StockAdjustment';
import { ProductStockAdjustmentsQueryKey } from '../../queryKeys/ProductStockAdjustmentsQueryKey';

export const useFetchProductStockAdjustments = (productId: number, type: StockAdjustmentType) => {
  const url = useAutomatorApiAccountUrl(
    PRODUCTS_PATH + `/${productId}/stock-adjustments?type=${type}`
  );
  return useFetchData<StockAdjustment[]>(url, ProductStockAdjustmentsQueryKey(productId, type));
};
