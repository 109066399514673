import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import ActionButton from '../../../../components/ActionButton';
import { Email } from '@mui/icons-material';
import { toast } from 'react-hot-toast';
import { useFetchProductNote } from '../../../../api/automator/products/useFetchProductNote';
import { useUpdateProductNote } from '../../../../api/automator/products/updaters/useUpdateProductNote';
import { tokens } from '../../../../locales/translationTokens';

interface UpdateProductNoteFormProps {
  onClick?: (note: string) => void;
  productId: number;
}

export const UpdateProductNoteForm = ({ onClick, productId }: UpdateProductNoteFormProps) => {
  const { data: productNote } = useFetchProductNote(productId);
  const [note, setNote] = useState('');

  useEffect(() => {
    if (productNote) {
      setNote(productNote.note);
    }
  }, [productNote]);

  const { t } = useTranslation();

  const productNoteId = productNote?.id ?? 0;

  const { mutate: update, isLoading } = useUpdateProductNote(productNoteId);

  const onUpdate = () => {
    update(
      {
        note: note,
      },
      {
        onSuccess: async () => {
          onClick?.(note);
          toast.success(t(tokens.automator.stock.product_note_dialog.note_updated));
        },
      }
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
      onBlur={onUpdate}
    >
      <TextField
        id="note-field"
        label={t(tokens.automator.stock.product_note_dialog.note)}
        variant="filled"
        multiline
        rows={4}
        fullWidth
        style={{ marginBottom: '1em' }}
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />

      <ActionButton
        icon={<Email />}
        label={t(tokens.automator.stock.product_note_dialog.save)}
        onClick={() => onUpdate()}
        isLoading={isLoading}
        variant="contained"
        color="primary"
        size="small"
      />
    </form>
  );
};
