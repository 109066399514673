import { PRODUCT_STOCKS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { RestocksQueryKey } from '../../queryKeys/RestocksQueryKey';
import { RestockStatus } from '../../../domain/automator/stock/RestockStatus';
import { ProductStockSummariesQueryKey } from '../../queryKeys/ProductStockSummariesQueryKey';
import { ProductStocksQueryKey } from '../../queryKeys/ProductStocksQueryKey';
import { ProductStockInsightQueryKey } from '../../queryKeys/ProductStockInsightQueryKey';

export const useRemoveProductStock = (productStockId: number, productId: number) => {
  const url = useAutomatorApiAccountUrl(PRODUCT_STOCKS_PATH + '/' + productStockId + '/remove');

  return useAuthorizedPost<undefined, undefined>(url, [
    ProductStockSummariesQueryKey(),
    ProductStocksQueryKey(productId),
    RestocksQueryKey(RestockStatus.DELIVERED),
    RestocksQueryKey(RestockStatus.ORDERED),
    ProductStockInsightQueryKey(),
  ]);
};
