import Typography from '@mui/material/Typography';

import SpokeIconOutlined from '@mui/icons-material/SpokeOutlined';
import Stack from '@mui/material/Stack';

interface SubStockAmountProps {
  amount: number;
}

export const SubStockAmount = ({ amount }: SubStockAmountProps) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <SpokeIconOutlined />
      <Typography>{amount}</Typography>
    </Stack>
  );
};
