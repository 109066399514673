import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import StockAdjustment from '../../../../domain/automator/stock/StockAdjustment';
import getShortendAlphabeticFormatFromDate from '../../../../helpers/getShortendAlphabeticFormatFromDate';
import { StockAdjustmentType } from '../../../../domain/automator/stock/StockAdjustmentType';
import SalesStockAdjustmentTableRow from './SalesStockAdjustmentTableRow';

interface StockAdjustmentTableRowProps {
  stockAdjustment: StockAdjustment;
}

export const StockAdjustmentTableRow = ({ stockAdjustment }: StockAdjustmentTableRowProps) => {
  if (stockAdjustment.type === StockAdjustmentType.SALES) {
    return (
      <SalesStockAdjustmentTableRow
        productId={stockAdjustment.stockValues.productId}
        date={stockAdjustment.date}
        quantity={stockAdjustment.stockValues.quantity}
      />
    );
  } else {
    return (
      <TableRow>
        <TableCell>
          <Typography>
            {getShortendAlphabeticFormatFromDate(new Date(stockAdjustment.date), false, true)}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography>{stockAdjustment.stockValues.quantity}</Typography>
        </TableCell>
      </TableRow>
    );
  }
};
