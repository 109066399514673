import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import { TableEmpty } from '../../../../components/TableEmpty';
import { StockAdjustmentType } from '../../../../domain/automator/stock/StockAdjustmentType';
import { useFetchProductStockAdjustments } from '../../../../api/automator/stock/useFetchProductStockAdjustments';
import { StockAdjustmentTableRow } from './StockAdjustmentTableRow';

interface StockAdjustmentsTableProps {
  productId: number;
  type: StockAdjustmentType;
}

export const StockAdjustmentsListTable = ({ productId, type }: StockAdjustmentsTableProps) => {
  const { data: stockAdjustments, isLoading } = useFetchProductStockAdjustments(productId, type);

  const { t } = useTranslation();

  if (isLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (stockAdjustments!.length == 0) {
    return (
      <TableEmpty message={t(tokens.automator.stock.product_stock_summaries_table.no_items)} />
    );
  }

  return (
    <Scrollbar>
      <Table size="small">
        <TableBody>
          {stockAdjustments!
            .sort((a, b) => {
              return new Date(b.date).getTime() - new Date(a.date).getTime();
            })
            .map((stockAdjustment) => {
              return (
                <StockAdjustmentTableRow
                  key={stockAdjustment.id}
                  stockAdjustment={stockAdjustment}
                />
              );
            })}
        </TableBody>
      </Table>
    </Scrollbar>
  );
};
