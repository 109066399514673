import { Box, Card, Container } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactElement, ReactNode } from 'react';
import Alert from '@mui/material/Alert';
import { Seo } from './Seo';
import ActionButton from './ActionButton';
import { useTranslation } from 'react-i18next';

interface AutomatorPageProps {
  title: string;
  titleExtra?: ReactNode;
  children: ReactNode;
  topAlerts?: ReactElement<typeof Alert>[];
  bottomAlerts?: ReactElement<typeof Alert>[];
  buttons?: ReactElement<typeof ActionButton>[];
}

const AutomatorPage = ({
  title,
  children,
  topAlerts,
  bottomAlerts,
  buttons,
  titleExtra,
}: AutomatorPageProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Seo />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={4}>
            {topAlerts?.map((alert, index) => <Box key={index}>{alert}</Box>)}
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                alignContent="center"
              >
                <Typography variant="h4">{t(title)}</Typography>
                <Typography>{titleExtra}</Typography>
              </Stack>
              <Stack
                alignItems="center"
                direction="row"
                spacing={1}
              >
                {buttons}
              </Stack>
            </Stack>
            {bottomAlerts?.map((alert, index) => <Box key={index}>{alert}</Box>)}
            <Card>{children}</Card>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default AutomatorPage;
