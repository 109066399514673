import { EndpointQueryKey } from './EndpointQueryKey';
import { StockAdjustmentType } from '../../domain/automator/stock/StockAdjustmentType';

export const ProductStockAdjustmentsQueryKey = (productId?: number, type?: StockAdjustmentType) => {
  if (productId === undefined) {
    return [EndpointQueryKey.PRODUCT_STOCK_ADJUSTMENTS];
  }

  return [EndpointQueryKey.PRODUCT_STOCK_ADJUSTMENTS, { productId, type }];
};
