import { useFetchSuppliers } from '../../../../api/automator/stock/useFetchSuppliers';
import { tokens } from '../../../../locales/translationTokens';
import { DropdownSelect } from '../../shared/DropdownSelect';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useTranslation } from 'react-i18next';

interface SupplierDropdownSelectProps {
  supplierId: number | null;
  onSelect: (supplierId: number) => void;
}

export const SupplierDropdownSelect = ({ onSelect, supplierId }: SupplierDropdownSelectProps) => {
  const { data: suppliers, isLoading } = useFetchSuppliers();

  const { t } = useTranslation();

  const openDialog = useOpenDialog(DialogId.CREATE_SUPPLIER);

  const options = [
    {
      key: 0,
      value: t(tokens.automator.stock.supplier_dialog.create_supplier),
    },
  ];

  if (isLoading) {
    options.unshift({ key: -1, value: tokens.common.loading });
  } else {
    options.push(
      ...suppliers!.suppliers.map((supplier) => ({
        key: supplier.id,
        value: supplier.companyName,
      }))
    );
  }

  return (
    <DropdownSelect
      options={options}
      onSelect={(supplierId) => {
        if (supplierId == 0) {
          openDialog();
        } else {
          onSelect(supplierId);
        }
      }}
      selected={supplierId || undefined}
      label={tokens.automator.stock.restock_dialog.supplier}
    />
  );
};
