import { TableRow } from '@mui/material';
import Shipment from '../../../../domain/automator/shipments/Shipment';
import Typography from '@mui/material/Typography';
import ShipmentStatusChip from './ShipmentEventStatus';
import Stack from '@mui/material/Stack';
import { TransportEventCode } from '../../../../domain/automator/shipments/TransportEventCode';
import DaysUntilReturnText from './DaysUntilReturnText';
import ShipmentOverdueChip from './ShipmentOverdueChip';
import LoadShipmentEventsReport from '../../../../domain/automator/shipments/LoadShipmentEventsReport';
import { ShipmentEventLoadingStatus } from '../../../../domain/automator/shipments/ShipmentEventLoadingStatus';
import ShipmentTransportInfo from './ShipmentTransporterInfo';
import { ShipmentTrackAndTraceTextField } from './ShipmentTrackAndTraceTextField';
import { HasOpenResolutionCaseIndicator } from '../../resolutions/components/HasOpenResolutionCaseIndicator';
import { TooltippedProductThumbnail } from '../../orders/components/TooltippedProductThumbnail';
import ShipmentCard from './ShipmentCard';
import HasReturnedToSenderIndicator from './HasReturnedToSenderIndicator';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import SpeedDialActionIcon from '../../shared/speeddial/SpeedDialActionIcon';

interface ShipmentRowProps {
  shipment: Shipment;
  loadShipmentEventsReport: LoadShipmentEventsReport;
}

const ShipmentRow = ({ shipment, loadShipmentEventsReport }: ShipmentRowProps) => {
  const determineShipmentEventLoadingStatus = (
    shipment: Shipment,
    loadShipmentEventsReport: LoadShipmentEventsReport
  ) => {
    if (loadShipmentEventsReport.pendingBolShipmentIds.includes(shipment.bolShipmentId)) {
      return ShipmentEventLoadingStatus.PENDING;
    } else if (loadShipmentEventsReport.finishedBolShipmentIds.includes(shipment.bolShipmentId)) {
      return ShipmentEventLoadingStatus.FINISHED;
    } else if (loadShipmentEventsReport.failedBolShipmentIds.includes(shipment.bolShipmentId)) {
      return ShipmentEventLoadingStatus.FAILED;
    } else {
      return ShipmentEventLoadingStatus.NOT_APPLICABLE;
    }
  };

  const hasTrackAndTraceUrl = shipment.transporterInfos.some((t) => t.trackAndTraceUrl != null);

  return (
    <TableRow key={shipment.id}>
      <SlimTableCell>
        <Stack
          direction="row"
          gap={1}
        >
          <ShipmentCard shipment={shipment} />

          {shipment.resolutionCases.map((resolutionCase) => {
            return (
              <HasOpenResolutionCaseIndicator
                key={resolutionCase.id}
                reducedResolutionCase={resolutionCase}
              />
            );
          })}

          {shipment.hasReturnedToSender && <HasReturnedToSenderIndicator />}
        </Stack>
      </SlimTableCell>

      <SlimTableCell>
        <Stack
          direction="row"
          gap={1}
        >
          <SpeedDialActionIcon
            orderId={shipment.order.id}
            hasNote={shipment.order.hasNote}
          />

          {!hasTrackAndTraceUrl ? (
            <ShipmentTrackAndTraceTextField shipmentId={shipment.id} />
          ) : (
            <></>
          )}
        </Stack>
      </SlimTableCell>

      <SlimTableCell>
        <Stack
          direction="row"
          gap={0.25}
        >
          {shipment.order.items.map((item) => {
            return (
              <TooltippedProductThumbnail
                key={item.offer.product.id}
                thumbnailUrl={item.offer.product.thumbnailUrl}
                productTitle={item.offer.product.title}
                height={40}
                quantity={item.quantity}
              />
            );
          })}
        </Stack>
      </SlimTableCell>

      <SlimTableCell>
        <Stack
          direction="row"
          alignItems={'center'}
        >
          <ShipmentStatusChip
            shipmentEventsLoadingStatus={determineShipmentEventLoadingStatus(
              shipment,
              loadShipmentEventsReport
            )}
            shipment={shipment}
          />
        </Stack>
      </SlimTableCell>

      <SlimTableCell>
        <Stack
          gap={'0.5rem'}
          direction={'row'}
        >
          <Typography>{shipment.expectedDeliveryDate}</Typography>

          {shipment.isTrackAndTraceable && (
            <>
              {shipment.daysUntilReturn != null && (
                <DaysUntilReturnText daysUntilReturn={shipment.daysUntilReturn!} />
              )}
              {isOverDue(shipment) && <ShipmentOverdueChip />}
            </>
          )}
        </Stack>
      </SlimTableCell>
      <SlimTableCell>
        <ShipmentTransportInfo shipment={shipment} />
      </SlimTableCell>
    </TableRow>
  );
};

export const isOverDue = (shipment: Shipment) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const hasTrackAndTraceUrl = shipment.transporterInfos.some((t) => t.trackAndTraceUrl != null);

  if (!hasTrackAndTraceUrl) {
    return false;
  }

  if (shipment.latestEvent == null && hasTrackAndTraceUrl) {
    return false;
  }

  return (
    new Date(shipment.expectedDeliveryDate) < currentDate &&
    shipment.latestEvent?.eventCode !== TransportEventCode.DELIVERED_AT_NEIGHBOURS &&
    shipment.latestEvent?.eventCode !== TransportEventCode.DELIVERED_AT_CUSTOMER &&
    shipment.latestEvent?.eventCode !== TransportEventCode.PICKED_UP_AT_PICK_UP_POINT
  );
};

export default ShipmentRow;
