import Stack from '@mui/material/Stack';
import { AutomatorProductMultiSelect } from '../../autoEmail/components/AutomatorProductMultiSelect';
import { Box } from '@mui/system';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import CurrencyTextField from '../../shared/CurrencyTextField';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { ActionIcon } from '../../orders/components/ActionIcon';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

interface RestockFormLineProps {
  onUpdate?: (item: ProductStockData) => void;
  onRemove?: () => void;
  item: ProductStockData;
  canUpdateQuantity?: boolean;
}

export interface ProductStockData {
  product_id: number;
  quantity: number;
  unit_price_in_cents: number | null;
  unit_transport_cost_in_cents: number | null;
  total_cubic_meters: number | null;
  total_weight_in_kg: number | null;
}

export const ProductStockForm = ({
  onUpdate,
  onRemove,
  item,
  canUpdateQuantity = true,
}: RestockFormLineProps) => {
  const { t } = useTranslation();

  const onUpdateProductId = (newProductId: number) => {
    onUpdate?.({
      ...item,
      product_id: newProductId,
    });
  };

  const onUpdateQuantity = (newQuantity: number) => {
    onUpdate?.({
      ...item,
      quantity: newQuantity,
    });
  };

  const onUpdateUnitPrice = (newUnitPrice: number) => {
    onUpdate?.({
      ...item,
      unit_price_in_cents: newUnitPrice,
    });
  };

  const onUpdateUnitTransportCost = (newUnitTransportCost: number) => {
    onUpdate?.({
      ...item,
      unit_transport_cost_in_cents: newUnitTransportCost,
    });
  };

  const onUpdateTotalWeight = (newTotalWeight: number) => {
    onUpdate?.({
      ...item,
      total_weight_in_kg: newTotalWeight,
    });
  };

  const onUpdateTotalSize = (newTotalSize: number) => {
    onUpdate?.({
      ...item,
      total_cubic_meters: newTotalSize,
    });
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
    >
      <Box width={500}>
        <AutomatorProductMultiSelect
          multiple={false}
          productIds={[item.product_id]}
          onSelect={(newProductId) => {
            onUpdateProductId(newProductId[0]);
          }}
          isDisabled={onRemove == undefined}
        />
      </Box>

      <Box width={100}>
        <TextField
          label={t(tokens.automator.stock.restock_dialog.quantity)}
          variant="outlined"
          value={item.quantity}
          disabled={!canUpdateQuantity}
          onChange={(e) => onUpdateQuantity(parseInt(e.target.value))}
        />
      </Box>

      <Box width={100}>
        <CurrencyTextField
          value={item.unit_price_in_cents || 0}
          onChange={onUpdateUnitPrice}
          label={t(tokens.automator.stock.restock_dialog.unit_price) as string}
        />
      </Box>

      <Box width={100}>
        <CurrencyTextField
          value={item.unit_transport_cost_in_cents || 0}
          onChange={onUpdateUnitTransportCost}
          label={t(tokens.automator.stock.restock_dialog.unit_transport_cost) as string}
        />
      </Box>

      <Box width={100}>
        <TextField
          label={t(tokens.automator.stock.restock_dialog.weight)}
          variant="outlined"
          value={item.total_weight_in_kg}
          onChange={(e) => onUpdateTotalWeight(parseInt(e.target.value))}
          inputProps={{
            input: {
              startAdornment: <InputAdornment position="start">kg</InputAdornment>,
            },
          }}
        />
      </Box>

      <Box width={100}>
        <TextField
          label={t(tokens.automator.stock.restock_dialog.volume)}
          variant="outlined"
          value={item.total_cubic_meters}
          onChange={(e) => onUpdateTotalSize(parseInt(e.target.value))}
        />
      </Box>

      {onRemove && (
        <ActionIcon
          onClick={onRemove}
          icon={<CancelOutlinedIcon />}
          tooltip={t(tokens.automator.stock.restock_dialog.remove_item)}
        />
      )}
    </Stack>
  );
};
