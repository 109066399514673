import { PRODUCT_NOTES_PATH } from '../../automatorApiPaths';
import ProductNote from '../../../../domain/automator/products/ProductNote';
import { ProductNoteQueryKey } from '../../../queryKeys/ProductNoteQueryKey';
import { useAuthorizedPost } from '../../../useAuthorizedPost';
import { useAutomatorApiAccountUrl } from '../../useAutomatorApiAccountUrl';

export const useUpdateProductNote = (productNoteId: number) => {
  const url = useAutomatorApiAccountUrl(`${PRODUCT_NOTES_PATH}/${productNoteId}`);
  return useAuthorizedPost<ProductNote, UpdateProductNoteData>(url, [ProductNoteQueryKey()]);
};

export interface UpdateProductNoteData {
  note: string;
}
