import { tokens } from '../../../locales/translationTokens';
import { useState } from 'react';
import AutomatorPage from '../../../components/AutomatorPage';
import ShipmentsPerDayTable from './components/ShipmentsPerDayTable';
import ShipmentsTable from './components/ShipmentsTable';
import ShipmentTabs from './components/ShipmentTabs';
import { useFetchShipments } from '../../../api/automator/shipments/useFetchShipments';
import { ShipmentsRequestType } from '../../../api/queryKeys/ShipmentsQueryKey';
import { useFetchShipmentsReport } from '../../../api/automator/shipments/useFetchShipmentsReport';

const ShipmentsPage = () => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [currentTab, setCurrentTab] = useState(ShipmentsRequestType.ALL);

  const { data: shipments, isLoading: isLoadingShipments } = useFetchShipments(
    currentTab,
    selectedDate
  );

  const {
    data: shipmentsReport,
    isLoading: isLoadingShipmentsReport,
    //setDoPoll,
  } = useFetchShipmentsReport();

  // const pageButtons = [];
  // if (
  //   shipments &&
  //   !isLoadingShipments &&
  //   currentTab != ShipmentsRequestType.RETURNED_TO_SENDER &&
  //   !(currentTab === ShipmentsRequestType.ALL && !selectedDate)
  // ) {
  //   pageButtons.push(
  //     <DoLoadShipmentEventsButton
  //       onClick={() => {
  //         setDoPoll(true);
  //       }}
  //       key={1}
  //       shipmentIds={shipments!.shipments.map((shipment) => shipment.id)}
  //     />
  //   );
  // }

  return (
    <AutomatorPage
      buttons={[]}
      title={tokens.automator.shipments.shipments}
    >
      <ShipmentTabs
        currentTab={currentTab}
        setCurrentTab={(status: ShipmentsRequestType) => {
          setCurrentTab(status);
        }}
        totals={shipmentsReport?.totals}
      />
      {currentTab === ShipmentsRequestType.ALL ? (
        <ShipmentsPerDayTable
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          shipments={shipments}
          shipmentsReport={shipmentsReport}
          isLoading={isLoadingShipments || isLoadingShipmentsReport}
        />
      ) : (
        <ShipmentsTable
          isLoading={isLoadingShipments || isLoadingShipmentsReport}
          shipments={shipments?.shipments}
          loadShipmentEventsReport={shipmentsReport?.loadEventsReport}
        />
      )}
    </AutomatorPage>
  );
};

export default ShipmentsPage;
