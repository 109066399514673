import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../components/ActionButton';
import Supplier from '../../../../domain/automator/stock/Supplier';

interface UpdateSupplierFormProps {
  supplier: Supplier | null;
  onSave: (supplier: Supplier) => void;
  isLoading: boolean;
}

interface FormInputs {
  contactName: string;
  companyName: string;
  hsCode: string;
  emailAddress: string;
  url: string;
}

export const UpdateSupplierForm = ({ supplier, onSave, isLoading }: UpdateSupplierFormProps) => {
  const { t } = useTranslation();

  const doUpdate = (data: Supplier) => {
    onSave(data);
  };

  const onSubmit = (data: FormInputs) => {
    doUpdate({
      id: supplier?.id || 0,
      contactName: data.contactName.trim(),
      companyName: data.companyName.trim(),
      hsCode: data.hsCode.trim(),
      emailAddress: data.emailAddress.trim(),
      url: data.url.trim(),
    });
  };

  const validationSchema = Yup.object({
    contactName: Yup.string().required(t(tokens.common.required) as string),
    companyName: Yup.string().required(t(tokens.common.required) as string),
    hsCode: Yup.string().required(t(tokens.common.required) as string),
    emailAddress: Yup.string()
      .required(t(tokens.common.required) as string)
      .email(t(tokens.common.incorrect) as string),
    url: Yup.string().required(t(tokens.common.required) as string),
  });

  const determineDefaultValues = (): Supplier => {
    if (!supplier) {
      return {
        id: 0,
        contactName: '',
        companyName: '',
        hsCode: '',
        emailAddress: '',
        url: '',
      };
    }

    return supplier;
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(validationSchema),
    defaultValues: determineDefaultValues(),
  });

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={3}>
        <Controller
          name="contactName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label={t(tokens.automator.stock.supplier_dialog.contact_name)}
              error={Boolean(errors.contactName)}
              helperText={errors.contactName?.message}
            />
          )}
        />
        <Controller
          name="companyName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label={t(tokens.automator.stock.supplier_dialog.company_name)}
              error={Boolean(errors.companyName)}
              helperText={errors.companyName?.message}
            />
          )}
        />
        <Controller
          name="hsCode"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label={t(tokens.automator.stock.supplier_dialog.hs_code)}
              error={Boolean(errors.hsCode)}
              helperText={errors.hsCode?.message}
            />
          )}
        />
        <Controller
          name="emailAddress"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label={t(tokens.automator.stock.supplier_dialog.email_address)}
              error={Boolean(errors.emailAddress)}
              helperText={errors.emailAddress?.message}
            />
          )}
        />
        <Controller
          name="url"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label={t(tokens.automator.stock.supplier_dialog.url)}
              error={Boolean(errors.url)}
              helperText={errors.url?.message}
            />
          )}
        />

        <ActionButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          label={t(tokens.automator.stock.supplier_dialog.save)}
          isLoading={isLoading}
        />
      </Stack>
    </form>
  );
};
