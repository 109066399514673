import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Restock from '../../../../domain/automator/stock/Restock';
import { RestockStatus } from '../../../../domain/automator/stock/RestockStatus';
import ActionButton from '../../../../components/ActionButton';
import { useRestockDelivered } from '../../../../api/automator/stock/useRestockDelivered';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import getShortendAlphabeticFormatFromDate from '../../../../helpers/getShortendAlphabeticFormatFromDate';

interface RestockTableRowProps {
  restock: Restock;
}

export const RestockTableRow = ({ restock }: RestockTableRowProps) => {
  const { mutate: doDeliver, isLoading } = useRestockDelivered(restock.id);

  const { t } = useTranslation();

  const onDeliver = () => {
    doDeliver(undefined, {
      onSuccess: () => {
        toast.success(t(tokens.automator.stock.restock_delivered));
      },
    });
  };

  return (
    <TableRow>
      <TableCell>
        <Typography>{restock.id}#</Typography>
      </TableCell>

      <TableCell>
        <Typography>{restock.supplier?.companyName}</Typography>
      </TableCell>

      <TableCell>
        <Typography>
          {getShortendAlphabeticFormatFromDate(new Date(restock.orderPlacedDate), false, true)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography>
          {getShortendAlphabeticFormatFromDate(new Date(restock.expectedDeliveryDate), false, true)}
        </Typography>
      </TableCell>

      <TableCell>
        {restock.actualDeliveryDate ? (
          <Typography>
            {getShortendAlphabeticFormatFromDate(new Date(restock.actualDeliveryDate), false, true)}
          </Typography>
        ) : (
          '-'
        )}
      </TableCell>

      <TableCell>
        {restock.status == RestockStatus.ORDERED && (
          <ActionButton
            label={t(tokens.automator.stock.restock_delivered)}
            onClick={onDeliver}
            isLoading={isLoading}
          />
        )}
      </TableCell>
    </TableRow>
  );
};
