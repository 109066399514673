import { EndpointQueryKey } from './EndpointQueryKey';
import { ProductStockSummariesSort } from '../automator/stock/useFetchProductStockSummaries';
import { SortDirection } from '../page/SortDirection';

export const ProductStockSummariesQueryKey = (
  page?: number,
  size?: number,
  sort?: ProductStockSummariesSort,
  direction?: SortDirection,
  productGroupId?: number,
  query?: string
) => {
  if (page === undefined || size === undefined) {
    return [EndpointQueryKey.PRODUCT_STOCK_SUMMARIES];
  }

  return [
    EndpointQueryKey.PRODUCT_STOCK_SUMMARIES,
    page,
    size,
    sort,
    direction,
    productGroupId,
    query,
  ];
};
