import { PRODUCT_STOCKS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { RestocksQueryKey } from '../../queryKeys/RestocksQueryKey';
import { RestockStatus } from '../../../domain/automator/stock/RestockStatus';
import { ProductStockSummariesQueryKey } from '../../queryKeys/ProductStockSummariesQueryKey';
import { ProductStocksQueryKey } from '../../queryKeys/ProductStocksQueryKey';
import { ProductStockInsightQueryKey } from '../../queryKeys/ProductStockInsightQueryKey';

export const useUpdateProductStock = (productStockId: number, productId: number) => {
  const url = useAutomatorApiAccountUrl(PRODUCT_STOCKS_PATH + '/' + productStockId);

  return useAuthorizedPost<undefined, UpdateProductStockData>(url, [
    ProductStockSummariesQueryKey(),
    ProductStocksQueryKey(productId),
    RestocksQueryKey(RestockStatus.DELIVERED),
    RestocksQueryKey(RestockStatus.ORDERED),
    ProductStockInsightQueryKey(),
  ]);
};

export interface UpdateProductStockData {
  product_stock_id: number;
  product_id: number;
  quantity: number;
  unit_price_in_cents: number | null;
  unit_transport_cost_in_cents: number | null;
  total_cubic_meters: number | null;
  total_weight_in_kg: number | null;
}
