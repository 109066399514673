import { Autocomplete, TextField } from '@mui/material';
import { tokens } from '../../../../locales/translationTokens';
import { useFetchAutomatorReducedProducts } from '../../../../api/automator/products/useFetchAutomatorReducedProducts';
import { truncateString } from '../../../../helpers/truncateString';
import { ProductThumbnail } from '../../orders/components/ProductThumbnail';
import AutomatorReducedProduct from '../../../../domain/automator/products/AutomatorReducedProduct';
import Stack from '@mui/material/Stack';

interface AutomatorProductMultiSelectProps {
  productIds: number[];
  onSelect: (productIds: number[]) => void;
  multiple?: boolean;
  isDisabled?: boolean;
}

interface OptionType {
  value: number;
  label: string;
  firstLetter: string;
  thumbnailUrl?: string;
}

export const AutomatorProductMultiSelect = ({
  productIds,
  onSelect,
  multiple = true,
  isDisabled = false,
}: AutomatorProductMultiSelectProps) => {
  const { data: products, isLoading } = useFetchAutomatorReducedProducts();

  const options: OptionType[] = isLoading
    ? []
    : products!.products.map((product: AutomatorReducedProduct) => ({
        value: product.id,
        label: truncateString(product.title, 60),
        firstLetter: product.title[0].toUpperCase(),
        thumbnailUrl: product.thumbnailUrl,
      }));

  const handleChange = (newValue: OptionType | OptionType[] | null) => {
    if (multiple) {
      onSelect((newValue as OptionType[]).map((option) => option.value));
    } else {
      onSelect(newValue ? [(newValue as OptionType).value] : []);
    }
  };

  return (
    <Autocomplete
      disabled={isLoading || isDisabled}
      multiple={multiple}
      options={options}
      getOptionLabel={(option: OptionType) => option.label}
      groupBy={(option: OptionType) => option.firstLetter}
      value={
        multiple
          ? options.filter((option) => productIds.includes(option.value))
          : options.find((option) => option.value === productIds[0]) || null
      }
      onChange={(_, value) => handleChange(value)}
      openOnFocus
      disableCloseOnSelect={multiple}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={tokens.automator.products.products}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {multiple && (
            <input
              style={{ marginRight: '20px', marginLeft: '-10px' }}
              type="checkbox"
              checked={selected}
              readOnly
            />
          )}
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
          >
            <ProductThumbnail
              thumbnailUrl={option.thumbnailUrl}
              height={40}
            />
            {option.label}
          </Stack>
        </li>
      )}
    />
  );
};
