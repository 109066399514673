import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import { TableEmpty } from '../../../../components/TableEmpty';
import { useFetchProductStocks } from '../../../../api/automator/stock/useFetchProductStocks';
import { ProductStockTableRow } from './ProductStockTableRow';

interface ProductStockListTableProps {
  productId: number;
}

export const ProductStockListTable = ({ productId }: ProductStockListTableProps) => {
  const { data: productStocks, isLoading } = useFetchProductStocks(productId);

  const { t } = useTranslation();

  if (isLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (productStocks!.length == 0) {
    return (
      <TableEmpty message={t(tokens.automator.stock.product_stock_summaries_table.no_items)} />
    );
  }

  return (
    <Scrollbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t(tokens.automator.stock.product_stock_table.type)}</TableCell>
            <TableCell>{t(tokens.automator.stock.product_stock_table.date)}</TableCell>
            <TableCell>{t(tokens.automator.stock.restock_dialog.quantity)}</TableCell>
            <TableCell>{t(tokens.automator.stock.restock_dialog.unit_price)}</TableCell>
            <TableCell>{t(tokens.automator.stock.restock_dialog.unit_price)}</TableCell>
            <TableCell>{t(tokens.automator.stock.restock_dialog.volume)}</TableCell>
            <TableCell>{t(tokens.automator.stock.restock_dialog.weight)}</TableCell>
            <TableCell>{t(tokens.automator.stock.product_stock_table.delivery_date)}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productStocks!
            .sort((a, b) => {
              return new Date(b.date).getTime() - new Date(a.date).getTime();
            })
            .map((productStock) => {
              return (
                <ProductStockTableRow
                  key={productStock.id}
                  productStock={productStock}
                />
              );
            })}
        </TableBody>
      </Table>
    </Scrollbar>
  );
};
