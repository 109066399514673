import * as React from 'react';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ReturnItemResolutionCaseMenu from './ReturnItemResolutionCaseMenu';
import CreateReturnMenu from './CreateReturnMenu';
import PopoverButton from '../../shared/PopoverButton';
import { useTranslation } from 'react-i18next';
import { useFetchOrderReturns } from '../../../../api/automator/orders/useFetchOrderReturns';

interface ReturnResolutionCasePopoverButtonProps {
  orderId: number;
}

export default function ReturnResolutionCasePopoverButton({
  orderId,
}: ReturnResolutionCasePopoverButtonProps) {
  const { t } = useTranslation();

  const { data: returns, isLoading } = useFetchOrderReturns(orderId);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const openReturnItems = returns!.flatMap((return_) =>
    return_.returnItems.filter((returnItem) => !returnItem.isHandled)
  );

  return (
    <PopoverButton
      id={'return-resolution-case-actions'}
      button={
        <ActionButton
          icon={<KeyboardArrowDownIcon />}
          color={'primary'}
          label={t(tokens.automator.returns.returns)}
          size="small"
          variant="text"
        />
      }
      content={
        <>
          {openReturnItems!.length > 0 ? (
            openReturnItems.map((returnItem) => (
              <ReturnItemResolutionCaseMenu
                key={returnItem.id}
                returnItem={returnItem}
              />
            ))
          ) : (
            <CreateReturnMenu orderId={orderId} />
          )}
        </>
      }
    />
  );
}
