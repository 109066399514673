import { useState } from 'react';
import { VatPercentageToggleButtons } from './components/VatPercentageToggleButtons';
import { useUpdateProductVatPercentage } from '../../../api/automator/products/useUpdateProductVatPercentage';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import AutomatorReducedProduct from '../../../domain/automator/products/AutomatorReducedProduct';
import { useFetchCustomerInvoiceSettings } from '../../../api/automator/customerInvoiceRequests/useFetchCustomerInvoiceSettings';

interface VatPercentageUpdateButtonsProps {
  product: AutomatorReducedProduct;
}

export const VatPercentageUpdateButtons = ({ product }: VatPercentageUpdateButtonsProps) => {
  const { data: customerInvoiceSettings, isLoading } = useFetchCustomerInvoiceSettings();

  const [vatPercentage, setVatPercentage] = useState<number | null>(product.vatPercentage);

  const { mutate: updateProductVatPercentage } = useUpdateProductVatPercentage(product.id);

  const { t } = useTranslation();

  const onUpdate = (vatPercentage: number) => {
    setVatPercentage(vatPercentage);
    updateProductVatPercentage(
      { vat_percentage: vatPercentage },
      {
        onSuccess: () => {
          toast.success(t(tokens.automator.products.vat_percentage_updated));
        },
      }
    );
  };

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <VatPercentageToggleButtons
      onSelect={onUpdate}
      value={vatPercentage != null ? vatPercentage : customerInvoiceSettings!.defaultVatPercentage}
    />
  );
};
