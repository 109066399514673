export const getShortenedAlphabeticFormatFromDate = (
  date: Date,
  withDayOfWeek: boolean = true,
  withYear: boolean = false
) => {
  let dayName = '';
  if (withDayOfWeek) {
    dayName = date.toLocaleDateString('Nl-nl', { weekday: 'long' }).substring(0, 2);
    dayName = dayName.charAt(0).toUpperCase() + dayName.slice(1) + ' ';
  }

  const dayOfMonth = date.getDate();
  const monthName = date.toLocaleString('Nl-nl', { month: 'long' }).substring(0, 3);

  return `${dayName}${dayOfMonth}-${monthName + (withYear ? `-${date.getFullYear()}` : '')}`;
};

export default getShortenedAlphabeticFormatFromDate;
