import { RESTOCKS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { RestocksQueryKey } from '../../queryKeys/RestocksQueryKey';
import { RestockStatus } from '../../../domain/automator/stock/RestockStatus';
import Restock from '../../../domain/automator/stock/Restock';
import { ProductStockData } from '../../../pages/automator/stock/dialogs/ProductStockForm';
import { ProductStockSummariesQueryKey } from '../../queryKeys/ProductStockSummariesQueryKey';
import { ProductStocksQueryKey } from '../../queryKeys/ProductStocksQueryKey';
import { ProductStockAdjustmentsQueryKey } from '../../queryKeys/ProductStockAdjustmentsQueryKey';
import { ProductStockInsightQueryKey } from '../../queryKeys/ProductStockInsightQueryKey';

export const useCreateRestock = () => {
  const url = useAutomatorApiAccountUrl(RESTOCKS_PATH);

  return useAuthorizedPost<Restock, CreateRestockData>(url, [
    ProductStockSummariesQueryKey(),
    RestocksQueryKey(RestockStatus.DELIVERED),
    RestocksQueryKey(RestockStatus.ORDERED),
    ProductStocksQueryKey(),
    ProductStockAdjustmentsQueryKey(),
    ProductStockInsightQueryKey(),
  ]);
};

export interface CreateRestockData {
  supplier_id: number | null;
  expected_delivery_date: string;
  items: ProductStockData[];
}
