import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ScaleOutlinedIcon from '@mui/icons-material/ScaleOutlined';

interface StockWeightProps {
  amount: number | null;
}

export const StockWeight = ({ amount }: StockWeightProps) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <ScaleOutlinedIcon />
      <Typography>{amount ? amount + ' kg' : '-'}</Typography>
    </Stack>
  );
};
