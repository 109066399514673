import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { AutomatorProductMultiSelect } from '../../autoEmail/components/AutomatorProductMultiSelect';
import Typography from '@mui/material/Typography';
import { VatPercentageToggleButtons } from '../../products/components/VatPercentageToggleButtons';
import { useFetchAutomatorReducedProducts } from '../../../../api/automator/products/useFetchAutomatorReducedProducts';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../components/ActionButton';
import { useBulkUpdateProductVatPercentage } from '../../../../api/automator/products/useBulkUpdateProductVatPercentage';
import { toast } from 'react-hot-toast';
import { TableLoading } from '../../../../components/TableLoading';
import { useFetchCustomerInvoiceSettings } from '../../../../api/automator/customerInvoiceRequests/useFetchCustomerInvoiceSettings';
import { useUpdateCustomerInvoiceSettings } from '../../../../api/automator/customerInvoiceRequests/useUpdateCustomerInvoiceSettings';

interface SetupVatPercentagesFormProps {
  onSave: () => void;
}

export const SetupVatPercentagesForm = ({ onSave }: SetupVatPercentagesFormProps) => {
  const { t } = useTranslation();

  const { data: products, isLoading: isLoadingProducts } = useFetchAutomatorReducedProducts();

  const { data: customerInvoiceSettings, isLoading: isLoadingAccountSettings } =
    useFetchCustomerInvoiceSettings();

  const [product0VatPercentageIds, setProduct0VatPercentageIds] = useState<number[]>([]);

  const [product9VatPercentageIds, setProduct9VatPercentageIds] = useState<number[]>([]);

  const [product21VatPercentageIds, setProduct21VatPercentageIds] = useState<number[]>([]);

  const [defaultVatPercentage, setDefaultVatPercentage] = useState<number | null>(null);

  const { mutate: updateBulk, isLoading: isLoadingBulkUpdate } =
    useBulkUpdateProductVatPercentage();

  const { mutate: updateSettings, isLoading } = useUpdateCustomerInvoiceSettings(
    customerInvoiceSettings?.id || 0
  );

  useEffect(() => {
    if (products) {
      setProduct0VatPercentageIds(
        products.products
          .filter((product) => product.vatPercentage === 0)
          .map((product) => product.id)
      );

      setProduct9VatPercentageIds(
        products.products
          .filter((product) => product.vatPercentage === 9)
          .map((product) => product.id)
      );

      setProduct21VatPercentageIds(
        products.products
          .filter((product) => product.vatPercentage === 21)
          .map((product) => product.id)
      );
    }
  }, [products]);

  useEffect(() => {
    if (customerInvoiceSettings) {
      setDefaultVatPercentage(customerInvoiceSettings.defaultVatPercentage);
    }
  }, [customerInvoiceSettings]);

  const onClickSave = () => {
    if (defaultVatPercentage == null) {
      return;
    }

    const dataSettings = {
      default_vat_percentage: defaultVatPercentage,
      automatically_upload_customer_invoices:
        customerInvoiceSettings!.automaticallyUploadCustomerInvoices,
      customer_invoice_information: customerInvoiceSettings!.customerInvoiceInformation,
      initial_customer_invoice_numbering_method:
        customerInvoiceSettings!.initialCustomerInvoiceNumberingMethod,
      customer_invoice_import_start_date: customerInvoiceSettings!.customerInvoiceImportStartDate,
      customer_invoice_auto_icp_method: customerInvoiceSettings!.customerInvoiceAutoICPMethod,
    };

    updateSettings(dataSettings, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.settings.settings_saved));
      },
    });

    const data = {
      updates: [
        {
          product_ids: product0VatPercentageIds,
          vat_percentage: 0,
        },
        {
          product_ids: product9VatPercentageIds,
          vat_percentage: 9,
        },
        {
          product_ids: product21VatPercentageIds,
          vat_percentage: 21,
        },
      ],
    };

    updateBulk(data, {
      onSuccess: () => {
        toast.success(t(tokens.automator.settings.default_vat_percentage));
        onSave();
      },
    });
  };

  if (isLoadingProducts || isLoadingAccountSettings) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  return (
    <Stack
      direction="column"
      gap={3}
    >
      {defaultVatPercentage != null && (
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
        >
          <Typography variant="h6">
            {t(tokens.automator.settings.default_vat_percentage)}
          </Typography>

          <VatPercentageToggleButtons
            onSelect={setDefaultVatPercentage}
            value={defaultVatPercentage}
          />
        </Stack>
      )}

      {defaultVatPercentage != 0 && (
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          width="100%"
        >
          <Typography variant="h6">0%</Typography>
          <AutomatorProductMultiSelect
            productIds={product0VatPercentageIds}
            onSelect={(productIds) => setProduct0VatPercentageIds(productIds)}
          />
        </Stack>
      )}

      {defaultVatPercentage != 9 && (
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          width="100%"
        >
          <Typography variant="h6">9%</Typography>
          <AutomatorProductMultiSelect
            productIds={product9VatPercentageIds}
            onSelect={(productIds) => setProduct9VatPercentageIds(productIds)}
          />
        </Stack>
      )}

      {defaultVatPercentage != 21 && (
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          width="100%"
        >
          <Typography variant="h6">21%</Typography>
          <AutomatorProductMultiSelect
            productIds={product21VatPercentageIds}
            onSelect={(productIds) => setProduct21VatPercentageIds(productIds)}
          />
        </Stack>
      )}

      <ActionButton
        label={t(tokens.automator.settings.save_settings)}
        onClick={onClickSave}
        color="primary"
        isLoading={isLoadingBulkUpdate || isLoading}
      />
    </Stack>
  );
};
