import { useTranslation } from 'react-i18next';
import { TableTabs } from '../../../../components/TableTabs';
import { tokens } from '../../../../locales/translationTokens';

interface ProductStockSummaryDetailsTabsProps {
  currentTab: ProductStockSummaryDetailsTab;
  setTab: (tab: ProductStockSummaryDetailsTab) => void;
}

export enum ProductStockSummaryDetailsTab {
  PRODUCT_STOCK = 'PRODUCT_STOCK',
  SALES = 'SALES',
  RETURNS = 'RETURNS',
  SUPPLIERS = 'SUPPLIERS',
  PRODUCT_STOCK_INSIGHT = 'PRODUCT_STOCK_INSIGHT',
  NOTES = 'NOTES',
}

const ProductStockSummaryDetailsTabs = ({
  currentTab,
  setTab,
}: ProductStockSummaryDetailsTabsProps) => {
  const { t } = useTranslation();

  const tabOptions = [
    {
      label: t(tokens.automator.stock.summary_details.tabs.restock),
      value: ProductStockSummaryDetailsTab.PRODUCT_STOCK,
    },
    {
      label: t(tokens.automator.stock.summary_details.tabs.sales),
      value: ProductStockSummaryDetailsTab.SALES,
    },
    {
      label: t(tokens.automator.stock.summary_details.tabs.returns),
      value: ProductStockSummaryDetailsTab.RETURNS,
    },
    {
      label: t(tokens.automator.stock.summary_details.tabs.suppliers),
      value: ProductStockSummaryDetailsTab.SUPPLIERS,
    },
    {
      label: t(tokens.automator.stock.summary_details.tabs.product_stock_insight),
      value: ProductStockSummaryDetailsTab.PRODUCT_STOCK_INSIGHT,
    },
    {
      label: t(tokens.automator.stock.summary_details.tabs.notes),
      value: ProductStockSummaryDetailsTab.NOTES,
    },
  ];

  return (
    <TableTabs
      value={currentTab}
      onChange={(tab: ProductStockSummaryDetailsTab) => {
        setTab(tab);
      }}
      tabOptions={tabOptions}
    />
  );
};

export default ProductStockSummaryDetailsTabs;
