import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { EventTriggerDropdown } from './EventTriggerDropdown';
import EventTrigger from '../../../../domain/automator/eventRules/EventTrigger';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';
import Stack from '@mui/material/Stack';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { RecipientType } from '../../../../domain/automator/messages/RecipientType';
import { MessageTemplatesDropdown } from '../../resolutions/components/MessageTemplatesDropdown';
import { tokens } from '../../../../locales/translationTokens';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import { CountryCode } from '../../../../domain/automator/orders/CountryCode';
import ActionButton from '../../../../components/ActionButton';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers';
import { AutomatorProductMultiSelect } from './AutomatorProductMultiSelect';
import { MessageTemplateType } from '../../../../domain/automator/messages/MessageTemplateType';
import EventRuleAction from '../../../../domain/automator/eventRules/EventRuleAction';
import EventRule from '../../../../domain/automator/eventRules/EventRule';

interface AutoMailEventRuleFormProps {
  eventRule: EventRule | null;
  onSave: (
    productIds: number[],
    messageTemplateId: number,
    distributionParty: DistributionParty | null,
    countryCode: CountryCode | null,
    recipientType: RecipientType,
    trigger: EventTrigger,
    delayHours: number | null,
    executeAtTime: string | null,
    isStartWithCase: boolean
  ) => void;
  isLoading: boolean;
}

interface FormInputs {
  product_ids: number[];
  trigger: EventTrigger;
  country: {
    netherlands: boolean;
    belgium: boolean;
  };
  distribution_party: {
    bol: boolean;
    retailer: boolean;
  };
  recipient: RecipientType;
  messageTemplateId: number | null;
  delayType: DelayType;
  delayHours: number | null;
  delayDays: number | null;
  delayTime: Date | null;
  startWithCase: boolean;
}

const AutoMailEventRuleForm = ({ isLoading, onSave, eventRule }: AutoMailEventRuleFormProps) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    trigger: yup.string().required(t(tokens.automator.auto_mail.form.trigger_required) as string),
    country: yup
      .object()
      .test(
        'check',
        t(tokens.automator.auto_mail.form.one_country_code_required) as string,
        (obj: any) => {
          return obj.netherlands || obj.belgium;
        }
      ),
    distribution_party: yup
      .object()
      .test(
        'check',
        t(tokens.automator.auto_mail.form.one_distribution_party_required) as string,
        (obj: any) => {
          return obj.bol || obj.retailer;
        }
      ),
    recipient: yup.string().required(),
    messageTemplateId: yup
      .number()
      .nullable()
      .required(t(tokens.automator.auto_mail.form.email_template_required) as string),

    delayType: yup.string().required(),
    delayHours: yup.number().when('delayType', (delayType: DelayType[], schema) => {
      return delayType.includes(DelayType.HOURS)
        ? schema
            .required(t(tokens.automator.auto_mail.form.hours_required) as string)
            .min(1, t(tokens.automator.auto_mail.form.hours_required) as string)
        : schema.nullable().notRequired();
    }),

    delayDays: yup.number().when('delayType', (delayType: DelayType[], schema) => {
      return delayType.includes(DelayType.DAYS)
        ? schema
            .required(t(tokens.automator.auto_mail.form.days_required) as string)
            .min(1, t(tokens.automator.auto_mail.form.days_required) as string)
        : schema.nullable().notRequired();
    }),

    delayTime: yup.string().when('delayType', (delayType: DelayType[], schema) => {
      return delayType.includes(DelayType.TIME)
        ? schema.required(t(tokens.automator.auto_mail.form.time_required) as string)
        : schema.nullable().notRequired();
    }),
  });

  const determineDelayType = (eventRuleAction: EventRuleAction | null) => {
    if (!eventRuleAction) {
      return DelayType.NONE;
    }

    if (eventRuleAction.executeInHours) {
      if (eventRuleAction.executeInHours % 24 === 0) {
        return DelayType.DAYS;
      } else {
        return DelayType.HOURS;
      }
    } else if (eventRuleAction.executeAtTime) {
      return DelayType.TIME;
    } else {
      return DelayType.NONE;
    }
  };

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    // @ts-ignore
    resolver: yupResolver(schema),
    defaultValues: {
      product_ids:
        eventRule?.products.map((product) => {
          return product.id;
        }) || [],
      trigger: eventRule?.trigger || EventTrigger.NEW_ORDER,
      country: eventRule?.countryCode
        ? {
            netherlands: eventRule?.countryCode === CountryCode.NL,
            belgium: eventRule?.countryCode === CountryCode.BE,
          }
        : {
            netherlands: true,
            belgium: true,
          },

      distribution_party: eventRule?.distributionParty
        ? {
            bol: eventRule?.distributionParty === DistributionParty.BOL,
            retailer: eventRule?.distributionParty === DistributionParty.RETAILER,
          }
        : {
            bol: true,
            retailer: true,
          },
      recipient:
        eventRule?.eventRuleActions[0]?.emailAction?.recipientType || RecipientType.CUSTOMER,
      delayType: determineDelayType(eventRule?.eventRuleActions[0] ?? null),
      startWithCase:
        eventRule?.eventRuleActions[0]?.emailAction?.isStartWithResolutionCase || false,
      messageTemplateId: eventRule?.eventRuleActions[0]?.emailAction?.messageTemplate.id || null,
    },
  });

  const onSubmit = (data: any) => {
    const determineDelayHours = (data: any) => {
      switch (data.delayType) {
        case DelayType.HOURS:
          return data.delayHours;
        case DelayType.DAYS:
          return data.delayDays ? data.delayDays * 24 : null;
        case DelayType.TIME:
          return null;
        default:
          return null;
      }
    };

    const determineExecuteAtTime = (data: any) => {
      switch (data.delayType) {
        case DelayType.TIME: {
          const delayTime = new Date(data.delayTime);
          const hours = delayTime.getHours().toString().padStart(2, '0');
          const minutes = delayTime.getMinutes().toString().padStart(2, '0');
          return hours + ':' + minutes + ':00';
        }
        default:
          return null;
      }
    };

    const countryCode =
      data.country.netherlands && data.country.belgium
        ? null
        : data.country.netherlands
        ? CountryCode.NL
        : CountryCode.BE;
    const distributionParty =
      data.distribution_party.bol && data.distribution_party.retailer
        ? null
        : data.distribution_party.bol
        ? DistributionParty.BOL
        : DistributionParty.RETAILER;
    const recipientType = data.recipient;
    const trigger = data.trigger;
    const messageTemplateId = data.messageTemplateId;

    onSave(
      data.product_ids,
      messageTemplateId,
      distributionParty,
      countryCode,
      recipientType,
      trigger,
      determineDelayHours(data),
      determineExecuteAtTime(data),
      data.startWithCase
    );
  };

  const delayType = watch('delayType');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        direction="column"
        gap={1}
        paddingBottom={2}
      >
        <Controller
          name="product_ids"
          control={control}
          render={({ field }) => (
            <AutomatorProductMultiSelect
              productIds={field.value || []}
              onSelect={(newValues) => {
                field.onChange(newValues);
              }}
            />
          )}
        />
        {errors.product_ids && <p>{errors.product_ids.root?.message}</p>}

        <Stack direction="row">
          <label>
            <Controller
              name="country.netherlands"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} />}
                  checked={field.value}
                  label={t(tokens.common.netherlands)}
                />
              )}
            />
          </label>

          <label>
            <Controller
              name="country.belgium"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} />}
                  checked={field.value}
                  label={t(tokens.common.belgium)}
                />
              )}
            />
          </label>
        </Stack>

        {errors.country && <p>{errors.country.root?.message}</p>}

        <Stack direction="row">
          <label>
            <Controller
              name="distribution_party.bol"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} />}
                  checked={field.value}
                  label={t(tokens.automator.shipments.distribution_party.bol)}
                />
              )}
            />
          </label>

          <label>
            <Controller
              name="distribution_party.retailer"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} />}
                  checked={field.value}
                  label={t(tokens.automator.shipments.distribution_party.retailer)}
                />
              )}
            />
          </label>
        </Stack>

        {errors.distribution_party && <p>{errors.distribution_party.root?.message}</p>}

        <Controller
          name="trigger"
          control={control}
          render={({ field }) => (
            <EventTriggerDropdown
              {...field}
              onSelect={(value) => field.onChange(value)}
              trigger={field.value as EventTrigger}
            />
          )}
        />
        {errors.trigger && <p>{errors.trigger.root?.message}</p>}
      </Stack>

      <hr />

      <Stack
        direction="column"
        gap={1}
        paddingBottom={2}
        paddingTop={2}
      >
        <Controller
          name="messageTemplateId"
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <MessageTemplatesDropdown
              type={MessageTemplateType.RULE}
              phase={null}
              returnRecoverability={null}
              onClick={(messageTemplateId) => field.onChange(messageTemplateId)}
              selectedMessageTemplateId={field.value}
            />
          )}
        />

        <Controller
          control={control}
          name="recipient"
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
            >
              <FormControlLabel
                value={RecipientType.TRANSPORTER}
                control={<Radio />}
                label={t(tokens.common.recipient_type.transporter)}
              />
              <FormControlLabel
                value={RecipientType.CUSTOMER}
                control={<Radio />}
                label={t(tokens.common.recipient_type.customer)}
              />
              <FormControlLabel
                value={RecipientType.BOL}
                control={<Radio />}
                label={t(tokens.common.recipient_type.bol)}
              />
            </RadioGroup>
          )}
        />

        {errors.recipient && <p>{errors.recipient.root?.message}</p>}
      </Stack>

      <Stack
        direction="column"
        gap={1}
        paddingBottom={2}
        paddingTop={2}
      >
        <Controller
          control={control}
          name="delayType"
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
            >
              <FormControlLabel
                value={DelayType.NONE}
                control={<Radio />}
                label={t(tokens.automator.auto_mail.delay_type.none)}
              />
              <FormControlLabel
                value={DelayType.HOURS}
                control={<Radio />}
                label={t(tokens.automator.auto_mail.delay_type.hours)}
              />
              <FormControlLabel
                value={DelayType.DAYS}
                control={<Radio />}
                label={t(tokens.automator.auto_mail.delay_type.days)}
              />
              <FormControlLabel
                value={DelayType.TIME}
                control={<Radio />}
                label={t(tokens.automator.auto_mail.delay_type.time)}
              />
            </RadioGroup>
          )}
        />

        {errors.delayType && <p>{errors.delayType.root?.message}</p>}

        {delayType === DelayType.HOURS && (
          <Controller
            name="delayHours"
            control={control}
            defaultValue={null}
            rules={{ required: true, min: 1 }}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                label={t(tokens.automator.auto_mail.form.delay_hours)}
                inputProps={{ min: 1 }}
                error={!!errors.delayHours}
              />
            )}
          />
        )}

        {delayType === DelayType.DAYS && (
          <Controller
            name="delayDays"
            control={control}
            defaultValue={null}
            rules={{ required: true, min: 1 }}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                label={t(tokens.automator.auto_mail.form.delay_days)}
                inputProps={{ min: 1 }}
                error={!!errors.delayDays}
              />
            )}
          />
        )}

        {delayType === DelayType.TIME && (
          <Controller
            name="delayTime"
            control={control}
            defaultValue={null}
            rules={{ required: true }}
            render={({ field }) => (
              <TimePicker
                {...field}
                label={t(tokens.automator.auto_mail.form.delay_time)}
                ampm={false}
                onChange={(date) => field.onChange(date)}
                // @ts-ignore
                renderInput={(params: any) => <TextField {...params} />}
              />
            )}
          />
        )}

        <Controller
          name="startWithCase"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} />}
              checked={field.value}
              label={t('Start with case')}
            />
          )}
        />
      </Stack>

      <ActionButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        label={t(
          eventRule
            ? tokens.automator.auto_mail.form.update_event_rule
            : tokens.automator.auto_mail.form.create_event_rule
        )}
        isLoading={isLoading}
      />
    </form>
  );
};

enum DelayType {
  NONE = 'none',
  HOURS = 'hours',
  DAYS = 'days',
  TIME = 'time',
}

export default AutoMailEventRuleForm;
