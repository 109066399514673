import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import * as React from 'react';
import { StockAdjustmentType } from '../../../../domain/automator/stock/StockAdjustmentType';
import { StockAdjustmentDirection } from '../../../../domain/automator/stock/StockAdjustmentDirection';
import { StockUnitPrice } from './StockUnitPrice';
import { StockUnitShippingCost } from './StockUnitShippingCost';
import { StockCubicMeters } from './StockCubicMeters';
import { StockWeight } from './StockWeight';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

interface ProductStockInsightTableRowProps {
  type: ProductStockInsightType;
  date?: string;
  quantity: number;
  direction: StockAdjustmentDirection;
  priceInCents?: number;
  transportCostInCents?: number;
  volume?: number;
  weight?: number;
}

export type ProductStockInsightType = StockAdjustmentType | 'CURRENT' | 'PREVIOUS';

export const ProductStockInsightTableRow = ({
  type,
  date,
  quantity,
  direction,
  priceInCents,
  transportCostInCents,
  volume,
  weight,
}: ProductStockInsightTableRowProps) => {
  const { t } = useTranslation();

  const determineTypeLabel = (type: ProductStockInsightType) => {
    switch (type) {
      case StockAdjustmentType.RESTOCK:
        return tokens.automator.stock.product_stock_insight.type.restock;
      case StockAdjustmentType.SALES:
        return tokens.automator.stock.product_stock_insight.type.sales;
      case StockAdjustmentType.STOCKTAKE:
        return tokens.automator.stock.product_stock_insight.type.stocktake;
      case StockAdjustmentType.RETURN:
        return tokens.automator.stock.product_stock_insight.type.return;
      case 'CURRENT':
        return tokens.automator.stock.product_stock_insight.type.current;
      case 'PREVIOUS':
        return new Date(date!).getDay() == 1
          ? tokens.automator.stock.product_stock_insight.type.previous
          : tokens.automator.stock.product_stock_insight.type.from_bol;
    }
  };

  return (
    <TableRow>
      <SlimTableCell>
        <Typography variant="body2">{t(determineTypeLabel(type))}</Typography>
      </SlimTableCell>
      <SlimTableCell>
        <Typography variant="body2">{date}</Typography>
      </SlimTableCell>
      <SlimTableCell>
        <Typography variant="body2">
          {(direction == StockAdjustmentDirection.DECREASE ? '-' : '') + quantity}
        </Typography>
      </SlimTableCell>
      <SlimTableCell>
        <Typography variant="body2">
          {priceInCents && <StockUnitPrice amount={priceInCents} />}
        </Typography>
      </SlimTableCell>
      <SlimTableCell>
        <Typography variant="body2">
          {transportCostInCents && <StockUnitShippingCost amount={transportCostInCents} />}
        </Typography>
      </SlimTableCell>
      <SlimTableCell>
        <Typography variant="body2">{volume && <StockCubicMeters amount={volume} />}</Typography>
      </SlimTableCell>
      <SlimTableCell>
        <Typography variant="body2">{weight && <StockWeight amount={weight} />}</Typography>
      </SlimTableCell>
    </TableRow>
  );
};
