import { useTranslation } from 'react-i18next';
import { useFetchProductStockInsight } from '../../../../api/automator/stock/useFetchProductStockInsight';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { tokens } from '../../../../locales/translationTokens';
import TableBody from '@mui/material/TableBody';
import { ProductStockInsightTableRow } from './ProductStockInsightTableRow';
import { StockAdjustmentType } from '../../../../domain/automator/stock/StockAdjustmentType';
import { StockAdjustmentDirection } from '../../../../domain/automator/stock/StockAdjustmentDirection';

interface ProductStockInsightTableProps {
  productId: number;
}

export const ProductStockInsightTable = ({ productId }: ProductStockInsightTableProps) => {
  const { data: productStockInsight, isLoading } = useFetchProductStockInsight(productId);

  const { t } = useTranslation();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!productStockInsight?.currentStockSummary?.currentStock) {
    return <div>No data</div>;
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{'TYPE'}</TableCell>
          <TableCell>{'DATE'}</TableCell>
          <TableCell>{t(tokens.automator.stock.restock_dialog.quantity)}</TableCell>
          <TableCell>{t(tokens.automator.stock.restock_dialog.unit_price)}</TableCell>
          <TableCell>{t(tokens.automator.stock.restock_dialog.unit_transport_cost)}</TableCell>
          <TableCell>{t(tokens.automator.stock.restock_dialog.volume)}</TableCell>
          <TableCell>{t(tokens.automator.stock.restock_dialog.weight)}</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        <ProductStockInsightTableRow
          type={'CURRENT'}
          date={productStockInsight!.endDate}
          direction={StockAdjustmentDirection.INCREASE}
          quantity={productStockInsight.currentStockSummary.currentStock}
        />

        {productStockInsight.restockStockAdjustments.map((stockAdjustment) => (
          <ProductStockInsightTableRow
            key={stockAdjustment.id}
            type={StockAdjustmentType.RESTOCK}
            date={stockAdjustment.date}
            quantity={stockAdjustment.stockValues.quantity}
            priceInCents={stockAdjustment.stockValues.unitPriceInCents || undefined}
            transportCostInCents={stockAdjustment.stockValues.unitTransportCostInCents || undefined}
            volume={stockAdjustment.stockValues.totalCubicMeters || undefined}
            weight={stockAdjustment.stockValues.totalCubicMeters || undefined}
            direction={stockAdjustment.direction}
          />
        ))}

        {productStockInsight.stocktakeStockAdjustments.map((stockAdjustment) => (
          <ProductStockInsightTableRow
            key={stockAdjustment.id}
            type={StockAdjustmentType.STOCKTAKE}
            date={stockAdjustment.date}
            quantity={stockAdjustment.stockValues.quantity}
            direction={stockAdjustment.direction}
          />
        ))}

        <ProductStockInsightTableRow
          type={StockAdjustmentType.RETURN}
          quantity={productStockInsight.returnsAmount}
          direction={StockAdjustmentDirection.INCREASE}
        />

        <ProductStockInsightTableRow
          type={StockAdjustmentType.SALES}
          quantity={productStockInsight.salesAmount}
          direction={StockAdjustmentDirection.DECREASE}
        />

        <ProductStockInsightTableRow
          type={'PREVIOUS'}
          date={productStockInsight!.startDate}
          direction={StockAdjustmentDirection.INCREASE}
          quantity={productStockInsight!.startStockSummary!.currentStock}
        />
      </TableBody>
    </Table>
  );
};
