import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { CustomerInvoiceRequestStatus } from '../../../../domain/automator/customerInvoiceRequests/CustomerInvoiceRequestStatus';

interface CustomerInvoiceRequestChipProps {
  customerInvoiceRequestStatus: CustomerInvoiceRequestStatus;
}

const CustomerInvoiceRequestChip = ({
  customerInvoiceRequestStatus,
}: CustomerInvoiceRequestChipProps) => {
  const { t } = useTranslation();

  const determineLabel = (customerInvoiceRequestStatus: CustomerInvoiceRequestStatus): string => {
    switch (customerInvoiceRequestStatus) {
      case CustomerInvoiceRequestStatus.INVOICE_INCORRECT:
        return tokens.automator.customer_invoice_requests.status.invoice_incorrect;
      case CustomerInvoiceRequestStatus.INVOICE_REQUESTED:
        return tokens.automator.customer_invoice_requests.status.invoice_requested;
      case CustomerInvoiceRequestStatus.INVOICE_UPLOADED:
        return tokens.automator.customer_invoice_requests.status.invoice_uploaded;
      case CustomerInvoiceRequestStatus.INVOICE_VIRUS_DETECTED:
        return tokens.automator.customer_invoice_requests.status.invoice_virus_detected;
      case CustomerInvoiceRequestStatus.FINISHED:
        return tokens.automator.customer_invoice_requests.status.finished;
      default:
        return '';
    }
  };

  return (
    <Chip
      size="small"
      label={t(determineLabel(customerInvoiceRequestStatus))}
      variant="outlined"
      color="default"
    />
  );
};

export default CustomerInvoiceRequestChip;
