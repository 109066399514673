import { ORDERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { OrderStatus } from '../../../domain/automator/orders/OrderStatus';
import Orders from '../../../domain/automator/orders/Orders';
import { DistributionParty } from '../../../domain/automator/orders/DistributionParty';
import { OrdersQueryKey } from '../../queryKeys/OrdersQueryKey';

export const useFetchOrders = (
  status: OrderStatus,
  productId: number | undefined,
  distributionParty: DistributionParty | undefined,
  startShipmentDate: string | undefined,
  endShipmentDate: string | undefined,
  isDisabled?: boolean
) => {
  const url = useAutomatorApiAccountUrl(
    ORDERS_PATH +
      '?status=' +
      status +
      (productId ? '&productId=' + productId : '') +
      (distributionParty ? '&distributionParty=' + distributionParty : '') +
      (startShipmentDate ? '&startShipmentDate=' + startShipmentDate : '') +
      (endShipmentDate ? '&endShipmentDate=' + endShipmentDate : '')
  );

  return useFetchData<Orders>(
    url,
    OrdersQueryKey(status, distributionParty, startShipmentDate, endShipmentDate, productId),
    {
      enabled: isDisabled === undefined ? true : !isDisabled,
    }
  );
};
