import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SvgIcon from '@mui/material/SvgIcon';
import SearchMdIcon from '@untitled-ui/icons-react/build/esm/SearchMd';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

interface ProductsPageSearchBarProps {
  isDisabled: boolean;
  onSearch: (query: string) => void;
  initialQuery?: string;
}

const ProductsPageSearchBar = ({
  isDisabled,
  onSearch,
  initialQuery,
}: ProductsPageSearchBarProps) => {
  const { t } = useTranslation();

  const [query, setQuery] = useState(initialQuery || '');

  return (
    <Box
      component="form"
      width={350}
      sx={{ flexGrow: 1 }}
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <OutlinedInput
        sx={{ backgroundColor: 'white' }}
        fullWidth
        value={query}
        disabled={isDisabled}
        onChange={(event) => {
          const query = event.target.value;
          setQuery(query);
          onSearch(query);
        }}
        placeholder={
          t(tokens.automator.products.search_products, {
            defaultValue: 'Search products...',
          }) as string
        }
        startAdornment={
          <InputAdornment position="start">
            <SvgIcon>
              <SearchMdIcon />
            </SvgIcon>
          </InputAdornment>
        }
      />
    </Box>
  );
};

export default ProductsPageSearchBar;
