import OrderItem from '../../../../domain/automator/orders/OrderItem';
import { OrderItemProductInfo } from './OrderItemProductInfo';
import Stack from '@mui/material/Stack';
import Order from '../../../../domain/automator/orders/Order';
import Checkbox from '@mui/material/Checkbox';
import { OrdersMode } from '../OrdersPage';
import { CancellationRequestChip } from './CancellationRequestChip';
import SpeedDialActionIcon from '../../shared/speeddial/SpeedDialActionIcon';
import { Box } from '@mui/system';

interface OrderItemCardProps {
  order: Order;
  orderItem: OrderItem;
  mode: OrdersMode;
  isSelected: boolean;
  onSelect: (orderItem: OrderItem) => void;
}

export const OrderItemCard = ({
  order,
  orderItem,
  mode,
  onSelect,
  isSelected,
}: OrderItemCardProps) => {
  return (
    <Stack
      gap={5}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      {mode == OrdersMode.BULK_ORDER_ITEMS && (
        <Checkbox
          checked={isSelected}
          onChange={() => onSelect(orderItem)}
        />
      )}

      <OrderItemProductInfo
        orderStatus={order.status}
        orderItemStatus={orderItem.status}
        quantity={orderItem.quantity}
        offer={orderItem.offer}
      />

      <Stack
        direction="column"
        gap={0.5}
        alignItems="center"
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={0.25}
        >
          <SpeedDialActionIcon
            orderId={order.id}
            orderItemId={orderItem.id}
            hasNote={order.hasNote}
          />
        </Stack>
        <Box width={130}>{orderItem.cancellationRequest && <CancellationRequestChip />}</Box>
      </Stack>
    </Stack>
  );
};
