export const getAlphabeticFormatFromDate = (
  date: Date,
  withDayOfWeek: boolean = true,
  withYear: boolean = false
) => {
  const dateObject = new Date(date);
  const day = dateObject.toLocaleDateString('nl-NL', { weekday: 'long' });
  const numericDate = dateObject.toLocaleDateString('nl-NL', { day: 'numeric', month: 'long' });
  return (
    (withDayOfWeek ? `${day}` + ` | ` : '') +
    `${numericDate}` +
    (withYear ? ` ${dateObject.getFullYear()}` : '')
  );
};
