import Stack from '@mui/material/Stack';
import { SortDirection } from './SortDirection';
import Typography from '@mui/material/Typography';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface TableHeaderSortProps<E> {
  title: string;
  direction?: SortDirection;
  sort: E;
  onSelect: (sort: E, direction: SortDirection) => void;
  isSelected: boolean;
}

export const TableHeaderSort = <E,>({
  title,
  direction,
  sort,
  isSelected,
  onSelect,
}: TableHeaderSortProps<E>) => {
  const onSort = () => {
    if (!isSelected) {
      onSelect(sort, direction || SortDirection.ASC);
    } else {
      onSelect(sort, direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC);
    }
  };

  return (
    <Stack
      style={{ cursor: 'pointer' }}
      onClick={onSort}
      direction="row"
      gap={0.5}
    >
      <Typography
        fontSize={'12px'}
        fontWeight={600}
      >
        {title}
      </Typography>

      {direction == SortDirection.DESC && (
        <KeyboardArrowUpIcon
          color={isSelected ? 'primary' : 'disabled'}
          fontSize="small"
        />
      )}

      {direction == SortDirection.ASC && (
        <KeyboardArrowDownIcon
          color={isSelected ? 'primary' : 'disabled'}
          fontSize="small"
        />
      )}
    </Stack>
  );
};
