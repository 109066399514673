import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { EndpointQueryKey } from '../../queryKeys/EndpointQueryKey';
import { CUSTOMER_INVOICE_SETTINGS_PATH } from '../automatorApiPaths';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import CustomerInvoiceInformation from '../../../domain/automator/account/CustomerInvoiceInformation';
import { CustomerInvoiceRequestsQueryKey } from '../../queryKeys/CustomerInvoiceRequestsQueryKey';
import { InitialCustomerInvoiceNumberingMethod } from '../../../domain/automator/retailers/InitialCustomerInvoiceNumberingMethod';
import { CustomerInvoiceAutoICPMethod } from '../../../domain/automator/account/CustomerInvoiceAutoICPMethod';
import CustomerInvoiceSettings from '../../../domain/automator/customerInvoiceRequests/CustomerInvoiceSettings';

export const useUpdateCustomerInvoiceSettings = (settingsId: number) => {
  const url = useAutomatorApiAccountUrl(`${CUSTOMER_INVOICE_SETTINGS_PATH}/${settingsId}`);
  return useAuthorizedPost<CustomerInvoiceSettings, UpdateCustomerInvoiceSettingsData>(url, [
    EndpointQueryKey.CUSTOMER_INVOICE_SETTINGS,
    CustomerInvoiceRequestsQueryKey(),
  ]);
};

export interface UpdateCustomerInvoiceSettingsData {
  default_vat_percentage: number;
  automatically_upload_customer_invoices: boolean;
  customer_invoice_information: CustomerInvoiceInformation | null;
  initial_customer_invoice_numbering_method: InitialCustomerInvoiceNumberingMethod | null;
  customer_invoice_import_start_date: string | null;
  customer_invoice_auto_icp_method: CustomerInvoiceAutoICPMethod | null;
}
