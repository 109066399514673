import { SUPPLIERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { SuppliersQueryKey } from '../../queryKeys/SuppliersQueryKey';
import Supplier from '../../../domain/automator/stock/Supplier';

export const useCreateSupplier = () => {
  const url = useAutomatorApiAccountUrl(SUPPLIERS_PATH);
  return useAuthorizedPost<Supplier, CreateSupplierData>(url, [SuppliersQueryKey()]);
};

export interface CreateSupplierData {
  contact_name: string;
  company_name: string;
  hs_code: string;
  email_address: string;
  url: string;
}
