import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { RestockForm } from './RestockForm';
import { toast } from 'react-hot-toast';
import { useCreateRestock } from '../../../../api/automator/stock/useCreateRestock';
import { ProductStockData } from './ProductStockForm';

export const CreateRestockDialog = () => {
  const id = DialogId.CREATE_RESTOCK;

  const { mutate: doCreateRestock } = useCreateRestock();

  const { isOpen, data } = useIsDialogOpen(id);

  const closeDialog = useCloseDialog(id);

  const { t } = useTranslation();

  const onSave = (
    supplierId: number | null,
    daysUntilDelivery: number,
    items: ProductStockData[]
  ) => {
    doCreateRestock(
      {
        supplier_id: supplierId,
        expected_delivery_date: new Date(
          new Date().getTime() + daysUntilDelivery * 24 * 60 * 60 * 1000
        ).toISOString(),
        items: items,
      },
      {
        onSuccess: () => {
          toast.success(t(tokens.automator.stock.restock_dialog.restock_created));
          data?.onCreate?.();
        },
      }
    );
    closeDialog();
  };

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          maxWidth={'lg'}
          title={t(tokens.automator.stock.restock_dialog.restock)}
        >
          <RestockForm
            productId={data.productId}
            onSave={onSave}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface CreateRestockData {
  productId?: number;
  onCreate?: () => void;
}
