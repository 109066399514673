import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { PRODUCT_STOCK_SUMMARIES_PATH } from '../automatorApiPaths';
import ProductStockSummaries from '../../../domain/automator/stock/ProductStockSummaries';
import { ProductStockSummariesQueryKey } from '../../queryKeys/ProductStockSummariesQueryKey';
import PaginatedResponse from '../../page/PaginatedResponse';
import { SortDirection } from '../../page/SortDirection';

export const useFetchProductStockSummaries = (
  page: number,
  size: number,
  sort: ProductStockSummariesSort,
  direction: SortDirection,
  productGroupId?: number,
  query?: string
) => {
  const queryParams = new URLSearchParams({
    page: page.toString(),
    size: size.toString(),
    sort: `${sort},${direction}`,
    ...(productGroupId !== undefined && { product_group_id: productGroupId.toString() }),
    ...(query && { query }),
  });

  const url = useAutomatorApiAccountUrl(
    `${PRODUCT_STOCK_SUMMARIES_PATH}?${queryParams.toString()}`
  );

  return useFetchData<PaginatedResponse<ProductStockSummaries>>(
    url,
    ProductStockSummariesQueryKey(page, size, sort, direction, productGroupId, query)
  );
};

export const enum ProductStockSummariesSort {
  TITLE = 'TITLE',
  CURRENT_STOCK = 'CURRENT_STOCK',
  DAYS_TILL_DEPLETION_30D = 'DAYS_TILL_DEPLETION_30D',
  TOTAL_VALUE_IN_CENTS = 'TOTAL_VALUE_IN_CENTS',
  AVERAGE_SALES_PER_DAY_30D = 'AVERAGE_SALES_PER_DAY_30D',
}
