import Typography from '@mui/material/Typography';

import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import Stack from '@mui/material/Stack';

interface StockAmountProps {
  amount: number;
}

export const StockAmount = ({ amount }: StockAmountProps) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <Inventory2OutlinedIcon />
      <Typography>{amount}</Typography>
    </Stack>
  );
};
