import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Supplier from '../../../../domain/automator/stock/Supplier';

interface SupplierTableRowProps {
  supplier: Supplier;
}

export const SupplierTableRow = ({ supplier }: SupplierTableRowProps) => {
  return (
    <TableRow>
      <TableCell>
        <Typography>{supplier.companyName}</Typography>
      </TableCell>

      <TableCell>
        <Typography>{supplier.contactName}</Typography>
      </TableCell>

      <TableCell>
        <Typography>{supplier.hsCode}</Typography>
      </TableCell>

      <TableCell>
        <Typography>{supplier.emailAddress}</Typography>
      </TableCell>

      <TableCell>
        <Typography>{supplier.url}</Typography>
      </TableCell>

      <TableCell></TableCell>
    </TableRow>
  );
};
