import { useState } from 'react';
import { useFetchOrders } from '../../../../api/automator/orders/useFetchOrders';
import { OrderStatus } from '../../../../domain/automator/orders/OrderStatus';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import { WarningAlert } from '../../../../components/alerts/WarningAlert';
import { OrderTableRow } from '../components/OrderTableRow';
import { OrdersMode } from '../OrdersPage';
import ActionButton from '../../../../components/ActionButton';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import Order from '../../../../domain/automator/orders/Order';

const LOCAL_STORAGE_KEY = 'viewedAlerts';

export const useGenerateFailedOrderWarningAlerts = (distributionParty: DistributionParty) => {
  const { data: failedOrders, isLoading: isLoadingFailedOrders } = useFetchOrders(
    OrderStatus.FAILED,
    undefined,
    distributionParty,
    undefined,
    undefined
  );

  // Local state to track viewed alerts dynamically
  const [localViewedAlerts, setLocalViewedAlerts] = useState<Set<string>>(() => {
    const stored = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '[]');
    return new Set(stored);
  });

  const { t } = useTranslation();

  if (isLoadingFailedOrders || !failedOrders || failedOrders.orders.length === 0) {
    return [];
  }

  const handleAlertClose = (id: string) => {
    const updatedViewedAlerts = new Set(localViewedAlerts);
    updatedViewedAlerts.add(id);

    // Update local state and persist to localStorage
    setLocalViewedAlerts(updatedViewedAlerts);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(Array.from(updatedViewedAlerts)));
  };

  const closeAllAlerts = () => {
    const ids = ordersWithAlerts(failedOrders.orders).map((order) => generateId(order));
    ids.forEach((id) => handleAlertClose(id));
  };

  const generateId = (order: Order) => `${order.bolOrderId}_${order.status}`;

  const ordersWithAlerts = (orders: Order[]) =>
    orders.filter((order) => {
      const id = generateId(order);
      return !localViewedAlerts.has(id);
    });

  const alerts = ordersWithAlerts(failedOrders.orders).map((order) => {
    const id = `${order.bolOrderId}_${order.status}`;
    return (
      <WarningAlert
        key={id}
        onClose={() => handleAlertClose(id)}
      >
        <OrderTableRow
          order={order}
          mode={OrdersMode.VIEW}
          isOrderSelected={false}
          selectedOrderItemIds={[]}
          onSelectOrderItem={() => {}}
        />
      </WarningAlert>
    );
  });

  if (alerts.length > 0) {
    alerts.unshift(
      <ActionButton
        size="small"
        variant="outlined"
        label={t(tokens.automator.orders.close_all_alerts)}
        onClick={closeAllAlerts}
      />
    );
  }

  return alerts;
};
