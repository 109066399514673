import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { SUPPLIERS_PATH } from '../automatorApiPaths';
import { SuppliersQueryKey } from '../../queryKeys/SuppliersQueryKey';
import Suppliers from '../../../domain/automator/stock/Suppliers';

export const useFetchSuppliers = () => {
  const url = useAutomatorApiAccountUrl(SUPPLIERS_PATH);
  return useFetchData<Suppliers>(url, SuppliersQueryKey());
};
