import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { PRODUCTS_PATH } from '../automatorApiPaths';
import ProductStockInsight from '../../../domain/automator/stock/ProductStockInsight';
import { ProductStockInsightQueryKey } from '../../queryKeys/ProductStockInsightQueryKey';

export const useFetchProductStockInsight = (productId: number) => {
  const url = useAutomatorApiAccountUrl(`${PRODUCTS_PATH}/${productId}/stock-insight`);

  return useFetchData<ProductStockInsight>(url, ProductStockInsightQueryKey(productId));
};
