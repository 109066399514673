import { PRODUCTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import ProductNote from '../../../domain/automator/products/ProductNote';
import { useFetchData } from '../../useFetchData';
import { ProductNoteQueryKey } from '../../queryKeys/ProductNoteQueryKey';

export const useFetchProductNote = (productId: number) => {
  const url = useAutomatorApiAccountUrl(`${PRODUCTS_PATH}/${productId}/note`);
  return useFetchData<ProductNote>(url, [ProductNoteQueryKey(productId)]);
};

export interface CreateProductGroupData {
  name: string;
}
