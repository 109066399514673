import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ProductStockSummary from '../../../../domain/automator/stock/ProductStockSummary';
import { TooltippedProductThumbnail } from '../../orders/components/TooltippedProductThumbnail';
import { DepletionPeriodChip } from './DepletionPeriodChip';
import roundDownTo1Decimal from '../../../pd/helpers/roundDownTo1Decimal';
import { Collapse, ToggleButtonGroup } from '@mui/material';
import { ProductStockSummaryDetails } from './ProductStockSummaryDetails';
import { StockAmount } from './StockAmount';
import { StockValue } from './StockValue';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import { ProductGroupChip } from '../../products/components/ProductGroupChip';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import ActionButton from '../../../../components/ActionButton';
import { tokens } from '../../../../locales/translationTokens';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import * as React from 'react';
import { useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';

interface ProductStockSummaryTableRowProps {
  productStockSummary: ProductStockSummary;
  showDetails: boolean;
  setShowDetailsProductId: (productId: number) => void;
}

export const ProductStockSummaryTableRow = React.memo(
  ({
    productStockSummary,
    showDetails,
    setShowDetailsProductId,
  }: ProductStockSummaryTableRowProps) => {
    const [days, setDays] = useState<7 | 14 | 30>(30);

    const daysTillDepletion =
      days === 7
        ? productStockSummary.daysTillsDepletion7d
        : days === 14
        ? productStockSummary.daysTillsDepletion14d
        : productStockSummary.daysTillsDepletion30d;

    const openStocktakeDialog = useOpenDialog(DialogId.STOCKTAKE);

    const openCreateRestockDialog = useOpenDialog(DialogId.CREATE_RESTOCK);

    const updateShowDetailsProductId = () => {
      setShowDetailsProductId(productStockSummary.product.id);
    };

    const onCreateRestock = (productId: number) => {
      setShowDetailsProductId(productId);
    };

    return (
      <>
        <TableRow style={{ cursor: 'pointer' }}>
          <SlimTableCell onClick={updateShowDetailsProductId}>
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              padding={1}
            >
              <TooltippedProductThumbnail
                thumbnailUrl={productStockSummary.product.thumbnailUrl}
                productTitle={productStockSummary.product.title}
                height={60}
              />

              <Stack
                direction="column"
                gap={1}
              >
                {productStockSummary.productGroups.map((productGroup) => (
                  <Box key={productGroup.id}>
                    <ProductGroupChip productGroup={productGroup} />
                  </Box>
                ))}
              </Stack>
            </Stack>
          </SlimTableCell>

          <SlimTableCell onClick={updateShowDetailsProductId}>
            <StockAmount amount={productStockSummary.currentStock} />
          </SlimTableCell>

          <SlimTableCell onClick={updateShowDetailsProductId}>
            {daysTillDepletion != null ? (
              <DepletionPeriodChip daysTillDepletion={daysTillDepletion} />
            ) : (
              <Typography></Typography>
            )}
          </SlimTableCell>

          <SlimTableCell onClick={updateShowDetailsProductId}>
            <StockValue valueInCents={productStockSummary.totalValueInCents} />
          </SlimTableCell>

          <SlimTableCell>
            <ToggleButtonGroup
              color="primary"
              exclusive
              value={days}
              onChange={(_, value) => setDays(value as 7 | 14 | 30)}
              aria-label="Platform"
            >
              <ToggleButton
                style={{ width: 50 }}
                value={7}
              >
                {roundDownTo1Decimal(productStockSummary.averageSalesPerDay7d)}
              </ToggleButton>

              <ToggleButton
                style={{ width: 50 }}
                value={14}
              >
                {roundDownTo1Decimal(productStockSummary.averageSalesPerDay14d)}
              </ToggleButton>

              <ToggleButton
                style={{ width: 50 }}
                value={30}
              >
                {roundDownTo1Decimal(productStockSummary.averageSalesPerDay30d)}
              </ToggleButton>
            </ToggleButtonGroup>
          </SlimTableCell>

          <SlimTableCell align="right">
            <Stack
              direction="row"
              gap={1}
            >
              <ActionButton
                onClick={() =>
                  openStocktakeDialog({
                    product: productStockSummary.product,
                    currentStock: productStockSummary.currentStock,
                  })
                }
                label={tokens.automator.stock.stocktake_dialog.stocktake}
              />

              <ActionButton
                onClick={() =>
                  openCreateRestockDialog({
                    productId: productStockSummary.product.id,
                    onCreate: () => onCreateRestock(productStockSummary.product.id),
                  })
                }
                label={tokens.automator.stock.restock_dialog.restock}
              />
            </Stack>
          </SlimTableCell>
        </TableRow>

        <TableRow key={`collapse-${productStockSummary.product.id}`}>
          <SlimTableCell
            height={-100}
            colSpan={10}
            style={{ padding: 0, margin: 0 }}
          >
            <Collapse
              in={showDetails}
              unmountOnExit
            >
              <ProductStockSummaryDetails productId={productStockSummary.product.id} />
            </Collapse>
          </SlimTableCell>
        </TableRow>
      </>
    );
  },
  (prevProps, nextProps) => {
    // Custom comparison function: re-render only if showDetails or productStockSummary changes
    return (
      prevProps.showDetails === nextProps.showDetails &&
      prevProps.productStockSummary === nextProps.productStockSummary
    );
  }
);

ProductStockSummaryTableRow.displayName = 'ProductStockSummaryTableRow';
