import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import { TableEmpty } from '../../../../components/TableEmpty';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useFetchProductSuppliers } from '../../../../api/automator/stock/useFetchProductSuppliers';
import { SupplierTableRow } from './SupplierTableRow';

interface ProductSuppliersListTableProps {
  productId: number;
}

export const ProductSuppliersListTable = ({ productId }: ProductSuppliersListTableProps) => {
  const { data, isLoading } = useFetchProductSuppliers(productId);

  const { t } = useTranslation();

  if (isLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  const suppliers = data!.suppliers;

  if (suppliers!.length == 0) {
    return (
      <TableEmpty message={t(tokens.automator.stock.product_stock_summaries_table.no_items)} />
    );
  }

  return (
    <Scrollbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t(tokens.automator.stock.supplier_dialog.company_name)}</TableCell>
            <TableCell>{t(tokens.automator.stock.supplier_dialog.contact_name)}</TableCell>
            <TableCell>{t(tokens.automator.stock.supplier_dialog.hs_code)}</TableCell>
            <TableCell>{t(tokens.automator.stock.supplier_dialog.email_address)}</TableCell>
            <TableCell>{t(tokens.automator.stock.supplier_dialog.url)}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {suppliers!.map((supplier) => {
            return (
              <SupplierTableRow
                key={supplier.id}
                supplier={supplier}
              />
            );
          })}
        </TableBody>
      </Table>
    </Scrollbar>
  );
};
