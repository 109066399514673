import Chip from '@mui/material/Chip';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { amber, deepOrange, green, grey, orange, red } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import roundDownTo1Decimal from '../../../pd/helpers/roundDownTo1Decimal';
import Typography from '@mui/material/Typography';

interface DepletionPeriodChipProps {
  daysTillDepletion?: number;
  showDays?: boolean;
}

export const DepletionPeriodChip = ({
  daysTillDepletion,
  showDays = true,
}: DepletionPeriodChipProps) => {
  const { t } = useTranslation();

  const determineColor = (days?: number) => {
    if (days == undefined) {
      return;
    } else if (days < 1) {
      return red[600];
    } else if (days <= 7 * 2) {
      return deepOrange[700];
    } else if (days <= 7 * 4) {
      return orange[900];
    } else if (days <= 7 * 6) {
      return orange[600];
    } else if (days <= 7 * 8) {
      return orange[300];
    } else if (days <= 7 * 12) {
      return amber[200];
    } else if (days <= 7 * 16) {
      return green[100];
    } else if (days <= 7 * 20) {
      return green[300];
    } else if (days <= 7 * 24) {
      return green[700];
    } else if (days <= 7 * 52) {
      return green[900];
    } else {
      return grey[600];
    }
  };

  const determineLabel = (days?: number) => {
    if (days == undefined) {
      return tokens.automator.stock.depletion_period.unknown;
    } else if (days < 1) {
      return tokens.automator.stock.depletion_period.depleted;
    } else if (days <= 7 * 2) {
      return tokens.automator.stock.depletion_period.weeks_0_2;
    } else if (days <= 7 * 4) {
      return tokens.automator.stock.depletion_period.weeks_2_4;
    } else if (days <= 7 * 6) {
      return tokens.automator.stock.depletion_period.weeks_4_6;
    } else if (days <= 7 * 8) {
      return tokens.automator.stock.depletion_period.weeks_6_8;
    } else if (days <= 7 * 12) {
      return tokens.automator.stock.depletion_period.weeks_8_12;
    } else if (days <= 7 * 16) {
      return tokens.automator.stock.depletion_period.weeks_12_16;
    } else if (days <= 7 * 20) {
      return tokens.automator.stock.depletion_period.weeks_16_20;
    } else if (days <= 7 * 24) {
      return tokens.automator.stock.depletion_period.weeks_20_24;
    } else if (days <= 7 * 52) {
      return tokens.automator.stock.depletion_period.weeks_24_52;
    } else {
      return tokens.automator.stock.depletion_period.one_year_plus;
    }
  };

  return (
    <Chip
      style={{
        width: 225,
        backgroundColor:
          daysTillDepletion && daysTillDepletion <= 7 * 24
            ? determineColor(daysTillDepletion)
            : 'white',
        color:
          daysTillDepletion && daysTillDepletion <= 7 * 24
            ? 'white'
            : determineColor(daysTillDepletion),
      }}
      label={
        <Stack
          direction="row"
          justifyContent="space-between"
          gap={1}
        >
          <Typography>{t(determineLabel(daysTillDepletion))}</Typography>
          {daysTillDepletion && showDays && daysTillDepletion > 0 && (
            <Typography>{'| ' + roundDownTo1Decimal(daysTillDepletion) + 'd'}</Typography>
          )}
        </Stack>
      }
      variant={daysTillDepletion && daysTillDepletion <= 7 * 24 ? 'filled' : 'outlined'}
      size="small"
    />
  );
};
