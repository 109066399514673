import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SvgIcon from '@mui/material/SvgIcon';
import { tokens } from 'src/locales/translationTokens';
import { usePaths } from '../../routes/usePaths';

import { Building07, Building08, PackageCheck } from '@untitled-ui/icons-react';
import {
  BallotOutlined,
  CameraAltOutlined,
  Description,
  Email,
  Inventory,
  LocalShipping,
  PendingActions,
  PictureAsPdf,
  Rule,
  Settings,
  Toll,
  Widgets,
} from '@mui/icons-material';
import { AccountStatus } from '../../domain/automator/account/AccountStatus';
import { useFetchAccount } from '../../api/automator/account/useFetchAccount';
import Box from '@mui/material/Box';
import DescriptionIcon from '@mui/icons-material/Description';
import { AmountBadge } from './AmountBadge';

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useMenu = () => {
  const { t } = useTranslation();

  const paths = usePaths();

  const { data: account } = useFetchAccount();

  const menuItems = [
    {
      title: t(tokens.nav.orders),
      icon: (
        <SvgIcon fontSize="small">
          <PackageCheck />
        </SvgIcon>
      ),
      items: [
        {
          title: t(tokens.nav.vvb_orders),
          path: paths.automator.vvb_orders,
        },
        {
          title: t(tokens.nav.non_vvb_orders),
          path: paths.automator.non_vvb_orders,
        },
        {
          title: t(tokens.nav.resends),
          path: paths.automator.resends,

          label: <AmountBadge amount={account?.report.amountOfOpenResends || 0} />,
        },
        {
          title: t(tokens.nav.pinned_orders),
          path: paths.automator.pinned_orders,
        },
      ],
    },

    {
      title: t(tokens.nav.pick_sessions),
      path: paths.automator.pick_sessions,
      icon: (
        <SvgIcon fontSize="small">
          <PictureAsPdf />
        </SvgIcon>
      ),
      label: (
        <Box style={{ marginRight: 19 }}>
          <AmountBadge amount={account?.report.amountOfApprovalPendingPickSessions || 0} />
        </Box>
      ),
    },
    {
      title: t(tokens.nav.products),
      path: paths.automator.products,
      icon: (
        <SvgIcon fontSize="small">
          <Rule />
        </SvgIcon>
      ),
    },

    {
      title: t(tokens.nav.customer_invoice_requests),
      path: paths.automator.customer_invoice_requests,
      icon: (
        <SvgIcon fontSize="small">
          <DescriptionIcon />
        </SvgIcon>
      ),
      label: (
        <Box style={{ marginRight: 19 }}>
          <AmountBadge amount={account?.report.amountOfOpenCustomerInvoiceRequests || 0} />{' '}
        </Box>
      ),
    },

    {
      title: t(tokens.nav.shipments),
      path: paths.automator.shipments,
      icon: (
        <SvgIcon fontSize="small">
          <LocalShipping />
        </SvgIcon>
      ),
    },

    {
      title: t(tokens.nav.stock),
      path: paths.automator.stock,
      icon: (
        <SvgIcon fontSize="small">
          <Inventory />
        </SvgIcon>
      ),
    },

    {
      title: t(tokens.nav.returns),
      path: paths.automator.returns,
      icon: (
        <SvgIcon fontSize="small">
          <Widgets />
        </SvgIcon>
      ),
    },

    {
      title: t(tokens.nav.return_scans),
      path: paths.automator.return_scans,
      icon: (
        <SvgIcon fontSize="small">
          <CameraAltOutlined />
        </SvgIcon>
      ),
    },

    {
      title: t(tokens.nav.resolution_dossiers),
      path: paths.automator.resolution_dossiers,
      icon: (
        <SvgIcon fontSize="small">
          <BallotOutlined />
        </SvgIcon>
      ),
      label: <AmountBadge amount={account?.report.amountOfNewEmailMessages || 0} />,
    },

    {
      title: t(tokens.nav.auto_mail),
      path: paths.automator.auto_mail,
      icon: (
        <SvgIcon fontSize="small">
          <Email />
        </SvgIcon>
      ),
    },

    {
      title: t(tokens.nav.alerts),
      path: paths.automator.alerts,
      icon: (
        <SvgIcon fontSize="small">
          <PendingActions />
        </SvgIcon>
      ),

      label: <AmountBadge amount={account?.report.amountOfAwaitingAlerts || 0} />,
    },

    {
      title: t(tokens.nav.reimbursements),
      path: paths.automator.reimbursements,
      icon: (
        <SvgIcon fontSize="small">
          <Toll />
        </SvgIcon>
      ),
      label: <AmountBadge amount={account?.report.amountOfOpenReimbursements || 0} />,
    },

    {
      title: t(tokens.nav.reports),
      path: paths.automator.reports,
      icon: (
        <SvgIcon fontSize="small">
          <Description />
        </SvgIcon>
      ),
    },
  ];

  const settingNavItems = [
    {
      title: t(tokens.nav.retailers),
      path: paths.automator.retailers,
      icon: (
        <SvgIcon fontSize="small">
          <Building08 />
        </SvgIcon>
      ),
    },
  ];

  if (account && account.status != AccountStatus.ACTIVE) {
    settingNavItems.push({
      title: t(tokens.nav.subscribe),
      path: paths.automator.pricing,
      icon: (
        <SvgIcon fontSize="small">
          <Building07 />
        </SvgIcon>
      ),
    });
  }

  menuItems.push({
    title: t(tokens.nav.settings),
    icon: <Settings fontSize="small" />,
    items: settingNavItems,
  });

  return useMemo(() => {
    return [
      {
        items: menuItems,
      },
    ];
  }, [
    paths.auth.not_authorized,
    paths.auth.not_found,
    paths.auth.server_error,
    paths.automator.products,
    paths.automator.vvb_orders,
    paths.automator.pick_sessions,
    t,
    account,
  ]);
};
