import Chip from '@mui/material/Chip';
import {
  TransporterCode,
  TransporterCodeTokenMap,
} from '../../../../domain/automator/shipments/TransporterCode';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { ArrowRight } from '@mui/icons-material';
import * as React from 'react';
import { colors } from '@mui/material';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';

interface TransporterChipProps {
  transporterCode?: TransporterCode;
  transporterName?: string;
  trackAndTrace?: string;
  icon?: React.ReactElement;
  variant?: 'filled' | 'outlined';
  distributionParty?: DistributionParty;
  withColor?: boolean;
}

const TransporterChip = ({
  transporterCode,
  trackAndTrace,
  transporterName,
  icon,
  variant,
  distributionParty,
  withColor,
}: TransporterChipProps) => {
  const { t } = useTranslation();

  const determineLabel = (
    distributionParty?: DistributionParty,
    transporterCode?: TransporterCode
  ) => {
    if (transporterName) {
      return transporterName;
    }

    if (!transporterCode) {
      return t(tokens.automator.pick_sessions.transporter_codes.none);
    }

    if (
      distributionParty == DistributionParty.BOL &&
      transporterCode == TransporterCode.TNT_BRIEF
    ) {
      return t(TransporterCodeTokenMap[TransporterCode.TNT]);
    }

    return t(TransporterCodeTokenMap[transporterCode]);
  };

  const determineColor = (transporterCode?: TransporterCode) => {
    switch (transporterCode) {
      case TransporterCode.DHLFORYOU:
      case TransporterCode.DHL:
        return colors.green['A400'];
      case TransporterCode.TNT:
        return colors.yellow[500];
      case TransporterCode.RJP:
        return colors.green[500];
      case TransporterCode.BUDBEE:
        return colors.orange[500];
      case TransporterCode.TNT_BRIEF:
        return colors.purple[300];
      default:
        return colors.blue[500];
    }
  };

  const label = determineLabel(distributionParty, transporterCode);

  if (trackAndTrace) {
    return (
      <Chip
        style={{ borderRadius: 4 }}
        size="small"
        label={!icon ? label : undefined}
        color="primary"
        variant={variant ? variant : 'filled'}
        component={'a'}
        clickable
        href={trackAndTrace}
        target="_blank"
        rel="noopener noreferrer"
        icon={!icon ? <ArrowRight style={{ color: 'WHITE' }} /> : icon}
      />
    );
  } else {
    return (
      <Chip
        style={{
          borderRadius: 4,
          backgroundColor: withColor ? determineColor(transporterCode) : undefined,
          color: withColor ? 'WHITE' : undefined,
        }}
        size="small"
        label={label}
        variant={variant ? variant : 'outlined'}
      />
    );
  }
};

export default TransporterChip;
