import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { PRODUCTS_PATH } from '../automatorApiPaths';
import Suppliers from '../../../domain/automator/stock/Suppliers';
import { ProductSuppliersQueryKey } from '../../queryKeys/ProductSuppliersQueryKey';

export const useFetchProductSuppliers = (productId: number) => {
  const url = useAutomatorApiAccountUrl(PRODUCTS_PATH + `/${productId}/suppliers`);
  return useFetchData<Suppliers>(url, ProductSuppliersQueryKey(productId));
};
