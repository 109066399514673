import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import { RestockStatus } from '../../../../domain/automator/stock/RestockStatus';
import { useFetchRestocks } from '../../../../api/automator/stock/useFetchRestocks';
import { TableEmpty } from '../../../../components/TableEmpty';
import { RestockTableRow } from './RestockTableRow';

interface RestocksListTableProps {
  status: RestockStatus;
}

export const RestocksListTable = ({ status }: RestocksListTableProps) => {
  const { t } = useTranslation();

  const { data: restocks, isLoading } = useFetchRestocks(status);

  if (isLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (restocks!.length === 0) {
    return <TableEmpty message={t(tokens.automator.stock.restocks_table.no_items)} />;
  }

  return (
    <Scrollbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t(tokens.automator.stock.restocks_table.restock_number)}</TableCell>
            <TableCell>{t(tokens.automator.stock.restocks_table.supplier)}</TableCell>
            <TableCell>{t(tokens.automator.stock.restocks_table.order_placed_date)}</TableCell>
            <TableCell>{t(tokens.automator.stock.restocks_table.expected_delivery_date)}</TableCell>
            <TableCell>{t(tokens.automator.stock.restocks_table.actual_delivery_date)}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {restocks!
            .sort((a, b) => {
              return a.id > b.id ? -1 : 1;
            })
            .map((restock) => (
              <RestockTableRow
                key={restock.id}
                restock={restock}
              />
            ))}
        </TableBody>
      </Table>
    </Scrollbar>
  );
};
