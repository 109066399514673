import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { convertCentsToEuros } from '../../../pd/helpers/convertCentsToEuros';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';

interface StockValueProps {
  valueInCents: number | null;
}

export const StockValue = ({ valueInCents }: StockValueProps) => {
  if (valueInCents == null) {
    return <></>;
  }

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <DiscountOutlinedIcon />

      <Typography>€ {convertCentsToEuros(valueInCents)}</Typography>
    </Stack>
  );
};
