import { Collapse, TableCell, TableRow } from '@mui/material';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import Order from '../../../../domain/automator/orders/Order';
import { OrderListTable } from './OrderListTable';
import { CountryCode } from '../../../../domain/automator/orders/CountryCode';
import { OrdersMode } from '../OrdersPage';

interface OrderPerDayRowProps {
  isExpanded: boolean;
  orders?: Order[];
  distributionParty?: DistributionParty;
  selectedCountries: CountryCode[];
  isLoading: boolean;
  mode: OrdersMode;
}

const OrderPerDayRow = ({
  isExpanded,
  orders,
  distributionParty,
  isLoading,
  selectedCountries,
  mode,
}: OrderPerDayRowProps) => {
  return (
    <TableRow>
      <TableCell
        style={{ padding: 0 }}
        colSpan={10}
      >
        <Collapse
          in={isExpanded}
          unmountOnExit
        >
          <OrderListTable
            orders={orders}
            mode={mode}
            isLoading={isLoading}
            distributionParty={distributionParty}
            selectedCountries={selectedCountries}
            isError={false}
          />
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default OrderPerDayRow;
