import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { CustomerInvoiceRequestsListTable } from './components/CustomerInvoiceRequestsListTable';
import Alert from '@mui/material/Alert';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useTranslation } from 'react-i18next';
import CustomerInvoiceTabs, { CustomerInvoicesPageTab } from './components/CustomerInvoiceTabs';
import { useEffect, useState } from 'react';
import { CustomerInvoicesBundlesListTable } from './components/CustomerInvoicesBundlesListTable';
import { CustomerInvoiceSettingsTab } from './components/CustomerInvoiceSettingsTab';
import { useFetchRetailerSettings } from '../../../api/automator/retailers/useFetchRetailerSettings';
import { InitialCustomerInvoiceNumberingMethod } from '../../../domain/automator/retailers/InitialCustomerInvoiceNumberingMethod';
import { useFetchRetailers } from '../../../api/automator/retailers/useFetchRetailers';
import { useOpenDialog } from '../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../store/dialogs/DialogId';
import { useFetchCustomerInvoiceSettings } from '../../../api/automator/customerInvoiceRequests/useFetchCustomerInvoiceSettings';

const CustomerInvoicesPage = () => {
  const [currentTab, setTab] = useState(CustomerInvoicesPageTab.CUSTOMER_INVOICE_REQUESTS);

  const { data: customerInvoiceSettings, isLoading: isLoadingCustomerInvoiceSettings } =
    useFetchCustomerInvoiceSettings();

  const { data: retailers, isLoading: isLoadingRetailers } = useFetchRetailers();

  const { data: retailerSettings, isLoading: isLoadingRetailerSettings } =
    useFetchRetailerSettings();

  const openDialog = useOpenDialog(DialogId.SETUP_VAT_PERCENTAGE_PRODUCTS);

  const { t } = useTranslation();

  useEffect(() => {
    if (
      retailers?.some((retailer) => {
        return !retailer.hasCheckedVatPercentages && retailer.hasImportedProducts;
      })
    ) {
      openDialog();
      if (currentTab !== CustomerInvoicesPageTab.CUSTOMER_INVOICE_SETTINGS) {
        setTab(CustomerInvoicesPageTab.CUSTOMER_INVOICE_SETTINGS);
      }
    }
  }, [retailers]);

  if (isLoadingCustomerInvoiceSettings || isLoadingRetailerSettings || isLoadingRetailers) {
    return <>...</>;
  }

  const alerts = [];

  if (
    retailers?.some((retailer) => {
      return !retailer.hasImportedProducts;
    })
  ) {
    alerts.push(
      <Alert
        icon={<WarningAmberIcon fontSize="inherit" />}
        severity="success"
        style={{ fontSize: '2rem' }}
      >
        {t(tokens.automator.customer_invoice_requests.loading_products_in_progress)}
      </Alert>
    );
    if (currentTab !== CustomerInvoicesPageTab.CUSTOMER_INVOICE_SETTINGS) {
      setTab(CustomerInvoicesPageTab.CUSTOMER_INVOICE_SETTINGS);
    }
  }

  if (customerInvoiceSettings && customerInvoiceSettings.customerInvoiceInformation == null) {
    alerts.push(
      <Alert
        icon={<WarningAmberIcon fontSize="inherit" />}
        severity="success"
        style={{ fontSize: '2rem' }}
      >
        {t(tokens.automator.customer_invoice_requests.no_customer_invoice_information_warning)}
      </Alert>
    );
    if (currentTab !== CustomerInvoicesPageTab.CUSTOMER_INVOICE_SETTINGS) {
      setTab(CustomerInvoicesPageTab.CUSTOMER_INVOICE_SETTINGS);
    }
  }

  if (
    customerInvoiceSettings &&
    customerInvoiceSettings.initialCustomerInvoiceNumberingMethod == null
  ) {
    alerts.push(
      <Alert
        icon={<WarningAmberIcon fontSize="inherit" />}
        severity="success"
        style={{ fontSize: '2rem' }}
      >
        {t(
          tokens.automator.customer_invoice_requests
            .no_initial_customer_invoice_numbering_method_warning
        )}
      </Alert>
    );
    if (currentTab !== CustomerInvoicesPageTab.CUSTOMER_INVOICE_SETTINGS) {
      setTab(CustomerInvoicesPageTab.CUSTOMER_INVOICE_SETTINGS);
    }
  }

  if (
    customerInvoiceSettings &&
    customerInvoiceSettings.initialCustomerInvoiceNumberingMethod ==
      InitialCustomerInvoiceNumberingMethod.MANUAL
  ) {
    const currentYear = new Date().getFullYear();

    if (
      retailerSettings!.retailerSettings.some((settings) => {
        return !settings.initialCustomerInvoiceNumbers.some((initialCustomerInvoiceNumber) => {
          if (initialCustomerInvoiceNumber.year === currentYear) {
            return true;
          }
        });
      })
    ) {
      alerts.push(
        <Alert
          icon={<WarningAmberIcon fontSize="inherit" />}
          severity="success"
          style={{ fontSize: '2rem' }}
        >
          {t(tokens.automator.customer_invoice_requests.no_customer_invoice_numbering_warning)}
        </Alert>
      );
      if (currentTab !== CustomerInvoicesPageTab.CUSTOMER_INVOICE_SETTINGS) {
        setTab(CustomerInvoicesPageTab.CUSTOMER_INVOICE_SETTINGS);
      }
    }
  }

  return (
    <AccountPage
      alerts={alerts}
      title={tokens.automator.customer_invoice_requests.customer_invoice_requests}
    >
      <CustomerInvoiceTabs
        selectedTab={currentTab}
        setTab={setTab}
      />

      {currentTab === CustomerInvoicesPageTab.CUSTOMER_INVOICE_REQUESTS && (
        <CustomerInvoiceRequestsListTable />
      )}

      {currentTab === CustomerInvoicesPageTab.CUSTOMER_INVOICES_BUNDLES && (
        <CustomerInvoicesBundlesListTable />
      )}

      {currentTab === CustomerInvoicesPageTab.CUSTOMER_INVOICE_SETTINGS && (
        <CustomerInvoiceSettingsTab
          retailerSettings={retailerSettings!.retailerSettings}
          settings={customerInvoiceSettings!}
        />
      )}
    </AccountPage>
  );
};

export default CustomerInvoicesPage;
