import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';

interface StockCubicMetersProps {
  amount: number | null;
}

export const StockCubicMeters = ({ amount }: StockCubicMetersProps) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <AspectRatioOutlinedIcon />
      <Typography>{amount ? amount + ' m3' : '-'}</Typography>
    </Stack>
  );
};
