import Stack from '@mui/material/Stack';
import ProductStockSummaryDetailsTabs, {
  ProductStockSummaryDetailsTab,
} from './ProductStockSummaryDetailsTabs';
import { useState } from 'react';
import { ProductStockListTable } from './ProductStockListTable';
import { StockAdjustmentsListTable } from './StockAdjustmentsListTable';
import { StockAdjustmentType } from '../../../../domain/automator/stock/StockAdjustmentType';
import { UpdateProductNoteForm } from './UpdateProductNoteForm';
import { ProductSuppliersListTable } from './ProductSuppliersListTable';
import { ProductStockInsightTable } from './ProductStockInsightTable';

interface ProductStockSummaryDetailsProps {
  productId: number;
}

export const ProductStockSummaryDetails = ({ productId }: ProductStockSummaryDetailsProps) => {
  const [tab, setTab] = useState(ProductStockSummaryDetailsTab.PRODUCT_STOCK);

  return (
    <Stack
      direction="column"
      gap={2}
    >
      <ProductStockSummaryDetailsTabs
        currentTab={tab}
        setTab={setTab}
      />

      {tab === ProductStockSummaryDetailsTab.PRODUCT_STOCK && (
        <ProductStockListTable productId={productId} />
      )}

      {tab === ProductStockSummaryDetailsTab.SALES && (
        <StockAdjustmentsListTable
          productId={productId}
          type={StockAdjustmentType.SALES}
        />
      )}

      {tab === ProductStockSummaryDetailsTab.RETURNS && (
        <StockAdjustmentsListTable
          productId={productId}
          type={StockAdjustmentType.RETURN}
        />
      )}

      {tab === ProductStockSummaryDetailsTab.NOTES && (
        <UpdateProductNoteForm productId={productId} />
      )}

      {tab === ProductStockSummaryDetailsTab.PRODUCT_STOCK_INSIGHT && (
        <ProductStockInsightTable productId={productId} />
      )}

      {tab === ProductStockSummaryDetailsTab.SUPPLIERS && (
        <ProductSuppliersListTable productId={productId} />
      )}
    </Stack>
  );
};
