import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { tokens } from '../../../../locales/translationTokens';
import { ProductStockData, ProductStockForm } from './ProductStockForm';
import ActionButton from '../../../../components/ActionButton';
import ProductStock from '../../../../domain/automator/stock/ProductStock';
import { toast } from 'react-hot-toast';
import { useUpdateProductStock } from '../../../../api/automator/stock/useUpdateProductStock';
import { ProductStockStatus } from '../../../../domain/automator/stock/ProductStockStatus';

interface UpdateProductStockFormProps {
  onClick?: () => void;
  productStock: ProductStock;
}

export const UpdateProductStockForm = ({ productStock, onClick }: UpdateProductStockFormProps) => {
  const initial = () => {
    return {
      product_id: productStock.stockValues.productId,
      quantity: productStock.stockValues.quantity,
      unit_price_in_cents: productStock.stockValues.unitPriceInCents,
      unit_transport_cost_in_cents: productStock.stockValues.unitTransportCostInCents,
      total_cubic_meters: productStock.stockValues.totalCubicMeters,
      total_weight_in_kg: productStock.stockValues.totalWeightInKg,
    };
  };

  const { mutate: updateProductStock, isLoading } = useUpdateProductStock(
    productStock.id,
    productStock.stockValues.productId
  );

  const { t } = useTranslation();

  const [stockValues, setStockValues] = useState<ProductStockData>(initial());

  const onUpdateItem = (item: ProductStockData) => {
    setStockValues(item);
  };

  const onSave = () => {
    updateProductStock(
      {
        product_stock_id: productStock.id,
        product_id: stockValues.product_id,
        quantity: stockValues.quantity,
        unit_price_in_cents: stockValues.unit_price_in_cents,
        unit_transport_cost_in_cents: stockValues.unit_transport_cost_in_cents,
        total_cubic_meters: stockValues.total_cubic_meters,
        total_weight_in_kg: stockValues.total_weight_in_kg,
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(t(tokens.automator.stock.update_product_stock.product_stock_updated));
        },
      }
    );
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      gap={2}
    >
      <ProductStockForm
        item={stockValues}
        onUpdate={(item) => onUpdateItem(item)}
        canUpdateQuantity={productStock.status == ProductStockStatus.ORDERED}
      />

      <ActionButton
        isDisabled={stockValues.quantity < 0}
        color="primary"
        isLoading={isLoading}
        label={t(tokens.automator.stock.restock_dialog.save)}
        onClick={() => onSave()}
      />
    </Stack>
  );
};
