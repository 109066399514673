import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { RESTOCKS_PATH } from '../automatorApiPaths';
import { RestocksQueryKey } from '../../queryKeys/RestocksQueryKey';
import { RestockStatus } from '../../../domain/automator/stock/RestockStatus';
import { ProductStockSummariesQueryKey } from '../../queryKeys/ProductStockSummariesQueryKey';
import Restock from '../../../domain/automator/stock/Restock';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { ProductStocksQueryKey } from '../../queryKeys/ProductStocksQueryKey';
import { ProductStockAdjustmentsQueryKey } from '../../queryKeys/ProductStockAdjustmentsQueryKey';
import { ProductStockInsightQueryKey } from '../../queryKeys/ProductStockInsightQueryKey';

export const useRestockDelivered = (restockId: number) => {
  const url = useAutomatorApiAccountUrl(RESTOCKS_PATH + `/${restockId}/delivered`);
  return useAuthorizedPost<Restock>(url, [
    RestocksQueryKey(RestockStatus.DELIVERED),
    RestocksQueryKey(RestockStatus.ORDERED),
    ProductStockSummariesQueryKey(),
    ProductStocksQueryKey(),
    ProductStockAdjustmentsQueryKey(),
    ProductStockInsightQueryKey(),
  ]);
};
