import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import { TableEmpty } from '../../../../components/TableEmpty';
import {
  ProductStockSummariesSort,
  useFetchProductStockSummaries,
} from '../../../../api/automator/stock/useFetchProductStockSummaries';
import Table from '@mui/material/Table';
import { ProductStockSummaryDepletionPeriodTableRow } from './ProductStockSummaryDepletionPeriodTableRow';
import { Fragment, useState } from 'react';
import { ProductStockSummaryTableRow } from './ProductStockSummaryTableRow';
import ProductStockSummary from '../../../../domain/automator/stock/ProductStockSummary';
import { SortDirection } from '../../../../api/page/SortDirection';

export const ProductStockSummariesInsightListTable = () => {
  const { t } = useTranslation();
  const [selectedDays, setSelectedDays] = useState<number | -1 | null>(null);
  const { data: paginatedProductStockSummaries, isLoading } = useFetchProductStockSummaries(
    0,
    1000000,
    ProductStockSummariesSort.TITLE,
    SortDirection.ASC
  );

  if (isLoading || !paginatedProductStockSummaries) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (paginatedProductStockSummaries.content.productStockSummaries.length === 0) {
    return (
      <TableEmpty message={t(tokens.automator.stock.product_stock_summaries_table.no_items)} />
    );
  }

  const items = paginatedProductStockSummaries.content.productStockSummaries;

  const getDepletionDays = (item: ProductStockSummary) => {
    if (item.currentStock === 0 && item.daysTillsDepletion30d !== 0) {
      return 0;
    } else if (item.daysTillsDepletion30d === null || item.daysTillsDepletion30d === 0) {
      return -1;
    } else if (item.currentStock !== 0 && item.daysTillsDepletion30d < 14) {
      return 1;
    } else if (item.daysTillsDepletion30d < 28) {
      return 15;
    } else if (item.daysTillsDepletion30d < 56) {
      return 29;
    } else if (item.daysTillsDepletion30d < 84) {
      return 57;
    } else {
      return 85;
    }
  };

  // Define the type for groupedItems explicitly.
  type GroupedItemsType = { [key: number]: ProductStockSummary[] };

  const groupedItems = items.reduce((groups: GroupedItemsType, item) => {
    const depletionDays = getDepletionDays(item);
    if (!groups[depletionDays]) {
      groups[depletionDays] = [];
    }
    groups[depletionDays].push(item);
    return groups;
  }, {} as GroupedItemsType);

  const depletionDaysValues = [0, 1, 15, 29, 57, 85, -1];

  return (
    <Table>
      {depletionDaysValues.map((depletionDays) => {
        const groupItems = groupedItems[depletionDays] || [];
        return (
          <Fragment key={depletionDays}>
            <ProductStockSummaryDepletionPeriodTableRow
              amount={groupItems.length}
              depletionDays={depletionDays === -1 ? undefined : depletionDays}
              onClick={() => setSelectedDays(depletionDays)}
            />
            {selectedDays === depletionDays &&
              groupItems.map((item) => (
                <ProductStockSummaryTableRow
                  key={item.product.id}
                  productStockSummary={item}
                  showDetails={false}
                  setShowDetailsProductId={() => null}
                />
              ))}
          </Fragment>
        );
      })}
    </Table>
  );
};
