import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ShoppingCartCheckoutOutlined from '@mui/icons-material/DirectionsBoatOutlined';
import { convertCentsToEuros } from '../../../pd/helpers/convertCentsToEuros';

interface StockUnitShippingCostProps {
  amount: number | null;
}

export const StockUnitShippingCost = ({ amount }: StockUnitShippingCostProps) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <ShoppingCartCheckoutOutlined />
      <Typography>{amount ? '€ ' + convertCentsToEuros(amount) : '-'}</Typography>
    </Stack>
  );
};
