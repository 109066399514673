import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import { ProductStockSummaryTableRow } from './ProductStockSummaryTableRow';
import {
  ProductStockSummariesSort,
  useFetchProductStockSummaries,
} from '../../../../api/automator/stock/useFetchProductStockSummaries';
import { useCallback, useState } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { SortDirection } from '../../../../api/page/SortDirection';
import { TableHeaderSort } from '../../../../api/page/TableHeaderSort';

import { StockValue } from './StockValue';
import ProductsPageSearchBar from '../../products/components/ProductsPageSearchBar';
import Stack from '@mui/material/Stack';
import { ProductGroupChip } from '../../products/components/ProductGroupChip';
import { useFetchProductGroups } from '../../../../api/automator/products/useFetchProductGroups';
import { debounce } from '@mui/material';

export const ProductStockSummariesListTable = () => {
  const PAGE_SIZE = 50;
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(ProductStockSummariesSort.AVERAGE_SALES_PER_DAY_30D);
  const [sortDirection, setSortDirection] = useState(SortDirection.DESC);
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [showDetailsProductId, setShowDetailsProductId] = useState<number | null>(null);
  const [productGroupId, setProductGroupId] = useState<number | undefined>(undefined);

  const { data: productGroups, isLoading: isLoadingProductGroups } = useFetchProductGroups();

  const { data: paginatedProductStockSummaries, isLoading } = useFetchProductStockSummaries(
    page,
    PAGE_SIZE,
    sort,
    sortDirection,
    productGroupId,
    query
  );

  const debouncedSearch = useCallback(
    debounce((query: string) => {
      setQuery(query);
    }, 500),
    []
  );

  if (isLoading || isLoadingProductGroups) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  const productStockSummaries = paginatedProductStockSummaries!.content;

  const onUpdateShowDetailsProductId = (productId: number) => {
    if (productId === showDetailsProductId) {
      setShowDetailsProductId(null);
    } else {
      setShowDetailsProductId(productId);
    }
  };

  const onSelectProductGroupId = (selectedProductGroupId: number) => {
    if (productGroupId === selectedProductGroupId) {
      setProductGroupId(undefined);
    } else {
      setProductGroupId(selectedProductGroupId);
    }
  };

  const onSelectSort = (sort: ProductStockSummariesSort, direction: SortDirection) => {
    setSort(sort);
    setSortDirection(direction);
  };

  const renderTableHeaderSort = (title: string, sortOfHeader: ProductStockSummariesSort) => {
    return (
      <TableHeaderSort
        title={t(title)}
        direction={sortOfHeader == sort ? sortDirection : undefined}
        sort={sortOfHeader}
        onSelect={(sort, direction) => onSelectSort(sort, direction)}
        isSelected={sort == sortOfHeader}
      />
    );
  };

  return (
    <Scrollbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <ProductsPageSearchBar
                initialQuery={query}
                isDisabled={isLoading}
                onSearch={(query) => debouncedSearch(query)}
              />
            </TableCell>

            <TableCell colSpan={3}>
              <Stack
                direction="row"
                gap={0.5}
              >
                {productGroups?.productGroups?.map((productGroup) => (
                  <ProductGroupChip
                    key={productGroup.id}
                    productGroup={productGroup}
                    onClick={() => onSelectProductGroupId(productGroup.id)}
                    isSelected={productGroupId == productGroup.id}
                  />
                ))}
              </Stack>
            </TableCell>

            <TableCell colSpan={1}>
              <StockValue valueInCents={productStockSummaries.totalValueInCents} />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              {renderTableHeaderSort(
                tokens.automator.stock.product_stock_summaries_table.product,
                ProductStockSummariesSort.TITLE
              )}
            </TableCell>
            <TableCell>
              {renderTableHeaderSort(
                tokens.automator.stock.product_stock_summaries_table.quantity,
                ProductStockSummariesSort.CURRENT_STOCK
              )}
            </TableCell>
            <TableCell>
              {renderTableHeaderSort(
                tokens.automator.stock.product_stock_summaries_table.depletion_period,
                ProductStockSummariesSort.DAYS_TILL_DEPLETION_30D
              )}
            </TableCell>
            <TableCell>
              {renderTableHeaderSort(
                tokens.automator.stock.product_stock_summaries_table.stock_value,
                ProductStockSummariesSort.TOTAL_VALUE_IN_CENTS
              )}
            </TableCell>
            <TableCell>
              {renderTableHeaderSort(
                tokens.automator.stock.product_stock_summaries_table
                  .average_sales_per_day_7d_14d_30d,
                ProductStockSummariesSort.AVERAGE_SALES_PER_DAY_30D
              )}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {productStockSummaries!.productStockSummaries.map((summary) => {
            return (
              <ProductStockSummaryTableRow
                key={summary.product.id}
                showDetails={showDetailsProductId == summary.product.id}
                setShowDetailsProductId={onUpdateShowDetailsProductId}
                productStockSummary={summary}
              />
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={paginatedProductStockSummaries!.pagination.totalElements}
        onPageChange={(_, page) => setPage(page)}
        page={page}
        rowsPerPage={PAGE_SIZE}
      />
    </Scrollbar>
  );
};
