import Stack from '@mui/material/Stack';
import * as React from 'react';
import TransporterChip from '../../shipments/components/TransporterChip';
import ReturnItem from '../../../../domain/automator/returns/ReturnItem';

interface ReturnResolutionCaseInfoProps {
  returnItem: ReturnItem;
}

export const ReturnResolutionCaseInfo = ({ returnItem }: ReturnResolutionCaseInfoProps) => {
  return (
    <Stack
      gap={1}
      direction="column"
    >
      {returnItem!.transporterInfos.map((info, index) => {
        return (
          info.trackAndTraceUrl && (
            <TransporterChip
              key={index}
              transporterCode={info.transporterCode}
              trackAndTrace={info.trackAndTraceUrl}
            />
          )
        );
      })}
    </Stack>
  );
};
