import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ProductStock from '../../../../domain/automator/stock/ProductStock';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { StockUnitPrice } from './StockUnitPrice';
import { StockUnitShippingCost } from './StockUnitShippingCost';
import { StockCubicMeters } from './StockCubicMeters';
import { StockWeight } from './StockWeight';
import ActionButton from '../../../../components/ActionButton';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { getAlphabeticFormatFromDate } from '../../../../helpers/getAlphabeticFormatFromDate';
import Stack from '@mui/material/Stack';
import { toast } from 'react-hot-toast';
import { useRemoveProductStock } from '../../../../api/automator/stock/useRemoveProductStock';
import getShortendAlphabeticFormatFromDate from '../../../../helpers/getShortendAlphabeticFormatFromDate';
import { SubStockAmount } from '../SubStockAmount';

interface ProductStockTableRowProps {
  productStock: ProductStock;
}

export const ProductStockTableRow = ({ productStock }: ProductStockTableRowProps) => {
  const { t } = useTranslation();

  const openUpdateProductStockDialog = useOpenDialog(DialogId.UPDATE_PRODUCT_STOCK);

  const onOpenWarningDialog = useOpenDialog(DialogId.WARNING);

  const { mutate: removeProductStock } = useRemoveProductStock(
    productStock.id,
    productStock.stockValues.productId
  );

  const onRemove = () => {
    removeProductStock(undefined, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.stock.remove_product_stock_dialog.product_stock_removed));
      },
    });
  };

  return (
    <TableRow>
      <TableCell>
        {productStock.restockId == null ? (
          <Chip
            label={t(tokens.automator.stock.restock_item_type.stocktake)}
            color={'secondary'}
            variant="outlined"
            size="small"
          />
        ) : (
          <Chip
            label={t(tokens.automator.stock.restock_item_type.restock)}
            color={'primary'}
            variant="outlined"
            size="small"
          />
        )}
      </TableCell>

      <TableCell>
        <Typography>
          {getShortendAlphabeticFormatFromDate(new Date(productStock.date), false, true)}
        </Typography>
      </TableCell>

      <TableCell>
        <SubStockAmount amount={productStock.stockValues.quantity} />
      </TableCell>

      <TableCell>
        <StockUnitPrice amount={productStock.stockValues.unitPriceInCents} />
      </TableCell>

      <TableCell>
        <StockUnitShippingCost amount={productStock.stockValues.unitTransportCostInCents} />
      </TableCell>

      <TableCell>
        <StockCubicMeters amount={productStock.stockValues.totalCubicMeters} />
      </TableCell>

      <TableCell>
        <StockWeight amount={productStock.stockValues.totalWeightInKg} />
      </TableCell>

      <TableCell>
        {productStock.expectedDeliveryDate ? (
          <Typography>
            {getAlphabeticFormatFromDate(new Date(productStock.expectedDeliveryDate), false, true)}
          </Typography>
        ) : (
          '-'
        )}
      </TableCell>

      <TableCell align="right">
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          {productStock.depletedAmount == 0 && productStock.reservedAmount == 0 && (
            <ActionButton
              color="warning"
              onClick={() =>
                onOpenWarningDialog({
                  title: t(tokens.automator.stock.remove_product_stock_dialog.remove_product_stock),
                  message: t(
                    tokens.automator.stock.remove_product_stock_dialog
                      .remove_product_stock_explanation
                  ),
                  buttonLabel: t(
                    tokens.automator.stock.remove_product_stock_dialog.remove_product_stock
                  ),
                  onConfirm: () => onRemove(),
                })
              }
              label={'X'}
              variant="outlined"
            />
          )}

          <ActionButton
            onClick={() =>
              openUpdateProductStockDialog({
                productStock: productStock,
              })
            }
            label={t(tokens.automator.stock.update_product_stock.update_product_stock)}
            variant={'outlined'}
          />
        </Stack>
      </TableCell>
    </TableRow>
  );
};
