import ActionButton from '../../../../components/ActionButton';
import { useState } from 'react';
import { tokens } from '../../../../locales/translationTokens';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import TextField from '@mui/material/TextField';
import { useCreateStocktake } from '../../../../api/automator/stock/useCreateStocktake';
import AutomatorReducedProduct from '../../../../domain/automator/products/AutomatorReducedProduct';
import { TooltippedProductThumbnail } from '../../orders/components/TooltippedProductThumbnail';

interface StocktakeFormProps {
  onClick?: () => void;
  product: AutomatorReducedProduct;
  currentStock: number;
}

export const StocktakeForm = ({ product, onClick, currentStock }: StocktakeFormProps) => {
  const [quantity, setQuantity] = useState<number | null>(null);

  const { t } = useTranslation();
  const { mutate: createStocktake, isLoading } = useCreateStocktake(product.id);

  const onSave = () => {
    if (quantity === null) {
      return;
    }

    createStocktake(
      { quantity: quantity, product_id: product.id },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(t(tokens.automator.stock.stocktake_dialog.stocktake_created));
        },
      }
    );
  };

  return (
    <Stack>
      <Stack
        direction="row"
        gap={2}
      >
        <TooltippedProductThumbnail
          thumbnailUrl={product.thumbnailUrl}
          productTitle={product.title}
          quantity={currentStock}
          height={100}
        />

        <Box width={300}>
          <TextField
            helperText={t(
              tokens.automator.stock.stocktake_dialog.total_amount_in_stock_explanation
            )}
            label={t(tokens.automator.stock.stocktake_dialog.total_amount_in_stock)}
            variant="outlined"
            value={quantity}
            onChange={(e) => setQuantity(parseInt(e.target.value))}
          />
        </Box>
      </Stack>

      <ActionButton
        label={t(tokens.common.save)}
        onClick={onSave}
        isLoading={isLoading}
      />
    </Stack>
  );
};
