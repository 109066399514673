import { DialogId } from '../../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import ProductStock from '../../../../domain/automator/stock/ProductStock';
import { UpdateProductStockForm } from './UpdateProductStockForm';

export const UpdateProductStockDialog = () => {
  const id = DialogId.UPDATE_PRODUCT_STOCK;

  const { isOpen, data } = useIsDialogOpen(id);

  const closeDialog = useCloseDialog(id);

  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={id}
          maxWidth={'lg'}
          title={t(tokens.automator.stock.update_product_stock.update_product_stock)}
        >
          <UpdateProductStockForm
            productStock={data.productStock}
            onClick={closeDialog}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface UpdateProductStockDialogData {
  productStock: ProductStock;
}
