import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { PRODUCT_STOCKS_PATH } from '../automatorApiPaths';
import { ProductStockSummariesQueryKey } from '../../queryKeys/ProductStockSummariesQueryKey';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { ProductStocksQueryKey } from '../../queryKeys/ProductStocksQueryKey';
import { ProductStockInsightQueryKey } from '../../queryKeys/ProductStockInsightQueryKey';

export const useCreateStocktake = (productId: number) => {
  const url = useAutomatorApiAccountUrl(PRODUCT_STOCKS_PATH + `/stocktake`);
  return useAuthorizedPost<undefined, CreateStocktakeData>(url, [
    ProductStockSummariesQueryKey(),
    ProductStocksQueryKey(productId),
    ProductStockInsightQueryKey(),
  ]);
};

export interface CreateStocktakeData {
  product_id: number;
  quantity: number;
}
